 import React from 'react'

import PartnerBanner from './PartnerBanner/PartnerBanner' ;
import ButtonPartner from './button-folder/ButtonPartner';
import Pagination from './pagination-folder/Pagination';




const PartnersPage = () => {
  return (
    <div>
     <PartnerBanner/>
    <ButtonPartner/>
    <Pagination/>
    </div>
  )
}

export default PartnersPage