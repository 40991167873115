import React from 'react'
import '../live-shopping/liveshoppping.css';
import frame from "../imgs/frame.png";
import networking from "../imgs/networking.png";
import beats from "../imgs/beats.png";
const LiveShopping = () => {
  return (
    <>
    <section className="live-virtual">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="Humanize_Heading py-3">Built for live Live Shopping</h1>
              <p className="build">
              Open new forms of consumer-facing revenue
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-12">
              <div className="card mb-3">
                <img src={frame} className="frame_img img-fluid" alt="..." />
                <div className='card-body'>
                  <div className="card-title">
                    <h3 >Enable Real-Time Shopper Engagement At Scale</h3>
                    <p >
                    Reach highly engaged viewers in real-time with shoppable live streams. Audiomeets' proven, scalable global network enables you to live stream interactive shopping events to millions of people anywhere in the world, on any device with confidence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="card mb-3">
                <img src={networking} className="frame_img img-fluid" alt="..." />
                <div className='card-body'>
                  <div className="card-title">
                    <h3>Reduce Shopping Cart Abandonment</h3>
                    <p>
                    Today, static images on product pages are not enough to drive shoppers to make decisions and complete orders. Empower content creators in your marketplace to make human connections with live video and chat to increase sale completion.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="card mb-3">
                <img src={beats} className="frame_img img-fluid" alt="..." />
                <div className='card-body'>
                  <div className="card-title">
                    <h3 >Streamline Social Shopping</h3>
                    <p >
                    Empower the new creator economy on your marketplace. Support thousands of live streams every day with real-time bidding and auctions. Enhance audience interaction with content moderation and cloud recording for real customer testimonials.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LiveShopping