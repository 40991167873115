import React from 'react'
import Terms from '../TermsCondition/Terms/Terms'
const TermsPage = () => {
    return (
      <div>
        <Terms/>
        </div>
    )
  }
  
  export default TermsPage;