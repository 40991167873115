import React from "react";
import "./Industries.css";

import { Link } from "react-router-dom";
const industries = () => {
  return (
    <>
      <section className="industries">
        <div className="container">
          <div className="row text-center">
            <div class="col-md-12 text-center">
              <h2 className="Industries_Heading">industries</h2>
            </div>
          </div>
          {/* 1st row */}


          <div className="row">
              <div className="col-md-10 mx-auto">
          <div class="row mb-4">
          <div class="col-lg-4 col-md-6">
          <Link to="/EducationSolution" > <div class="card text-center">
              <div class="card-body">
              <p class="pt-4 text-center edu">Education</p>
                <div class="card-img-overlay">
                  <p class="text"> It helps educational institutions, teachers, and students to get connected at a unified platform and then teach, learn and connect at ease with one another.</p>
                </div>
              </div>
            </div></Link>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card text-center">
              <div class="card-body">
                <p class="pt-4 text-center edu">Financial<br/>Services</p>
                <div class="card-img-overlay">
                  <p class="text">The institutes providing financial services can enable flexible working habits with innovative and customized solutions to clients.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
          <Link to="/LiveStreamPage" >   <div class="card text-center">
              <div class="card-body">
                <p class="pt-4 text-center edu">Live Streaming</p>
                <div class="card-img-overlay">
                  <p class="text">This platform can be very helpful for all government organizations to accelerate the process of overall digitization.</p>
                </div>
              </div>
            </div></Link>
          </div>
          <div class="col-lg-4 col-md-6">
          <Link to="/HealthSolution" >  <div class="card text-center">
              <div class="card-body">
              <p class="pt-4 text-center edu">Healthcare</p>
                <div class="card-img-overlay">
                  <p class="text">In the medical field also it helps to communicate with one another at ease across all spectrums by enabling your digital workforce to stay connected.
</p>
                </div>
              </div>
            </div></Link>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card text-center">
              <div class="card-body">
                <p class="pt-4 text-center edu">Manufacturing</p>
                <div class="card-img-overlay">
                  <p class="text">Our manufacturing organizations can increase productivity and streamline operations at a high rate through our innovative products and services.
</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
          <Link to="/RetailsPage" >  <div class="card text-center">
              <div class="card-body">
                <p class="pt-4 text-center edu">Retail</p>
                <div class="card-img-overlay">
                  <p class="text"> Audio meet helps retailers to implement innovative solutions to streamline operations, increase sales and thus improve customer satisfaction. </p>
                </div>
              </div>
            </div></Link>
          </div>

        </div>
              </div>
          </div>
    

     
          </div>
    </section>
    </>
  );
};
export default industries;
