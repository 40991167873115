import React from "react";
import EduSolutionBanner from  "./EduSolutionBanner/EduSolutionBanner"
import Virtual from  "./Virtual/Virtual";
 import BuiltEdu from  "./BuiltEdu/BuiltEdu";
 import Blocks from "./Blocks/Blocks";
import EngageStudent from "./EngageStudent/EngageStudent";
import Passionate from "./Passionate/Passionate";
import Audio from './audio-folder/Audio'
import Video from "./video-folder/Video";
const EducationSolution = () => {
  return (
    <div>
      <EduSolutionBanner/>
      <Virtual />
      <Video/>
      <BuiltEdu />
      <Blocks/>
      {/* <TreatPatients /> */}

      <EngageStudent />
      <Passionate />
      <Audio/>
    </div>
  );
};

export default EducationSolution;
