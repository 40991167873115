import React from "react";
import "../Cards/Cards.css";
const Cards = () => {
  return (
    <>
      <section className="empowering">
        <div className="container">
          <div className="row justify-content-center">
            <div className="heading-holder mb-4">
              <h2>Efficiently empowering different organizations across industries by</h2>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div class="card  mb-3" >
                <div class="card-header card-title1">Synchronize Communications</div>
                <div class="card-body">
                  <p className="helps">
                  It helps to Synchronize communications, connections, &
                  collaboration all together in the classrooms, meeting rooms,
                  operating rooms, and organizations.
                </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div class="card  mb-3" >
                <div class="card-header card-title1">Private</div>
                <div class="card-body">
                  <p className="helps">
                  It helps to privately communicate and share information among multiple users at once where they are inaudible to one another.
                </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div class="card  mb-3" >
                <div class="card-header card-title1">Safe and Reliable</div>
                <div class="card-body">
                  <p className="helps">
                  Safely and reliably connect global staff, designers, factories, and supply chains for smooth operations.
                </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div class="card  mb-3" >
                <div class="card-header card-title1">Empowering Users</div>
                <div class="card-body">
                  <p className="helps">
                  Aim to empower teachers and students to provide more equitable educational opportunities.
                </p>
                </div>
              </div>
            </div>


            <div className="col-lg-3 col-md-6 col-12">
              <div class="card  mb-3" >
                <div class="card-header card-title1">Bringing Teams Together</div>
                <div class="card-body">
                  <p className="helps">
                  We bring teams together Safely, securely, privately, and efficiently to connect and collaborate with the entire team.
                </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div class="card  mb-3" >
                <div class="card-header card-title1">Smooth Workflow</div>
                <div class="card-body">
                  <p className="helps">
                  Reimagining workspaces, powering organizations across industries and geographies to get things done smoothly.
                </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div class="card  mb-3" >
                <div class="card-header card-title1">Better Communication</div>
                <div class="card-body">
                  <p className="helps">
                  Facilitates communications, connects people, and collaborates better together in the meeting room, classroom, operating room, etc.
                </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div class="card  mb-3" >
                <div class="card-header card-title1">Host a Variety of Events</div>
                <div class="card-body">
                  <p className="helps">
                  Set up external-facing marketing events or internal employee events. Schedule your events to be a single-session, multi-session, or concurrent session, with the ability to post recordings after the event.
                </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div class="card  mb-3" >
                <div class="card-header card-title1">Access On Any Device</div>
                <div class="card-body">
                  <p className="helps">
                  The audio meet can be accessed through any device it is compatible with all devices.
                </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div class="card  mb-3" >
                <div class="card-header card-title1">Single Platform</div>
                <div class="card-body">
                  <p className="helps">
                  An all-in-one platform with the power to create the best experiences that everyone will love.​​
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Cards;
