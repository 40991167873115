import React from "react";
import { Link } from 'react-router-dom';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "../testimonial-slider/slidertestimonial.css"


const Testimonial = () => {
  return (
    <>
       <section className='testimonials'>
            <div className='row'>
                <div className='col-md-12'>
                    <Swiper className='justify-content-between'
                        // install Swiper modules
                        modules={[Navigation, Autoplay]}
                        centeredSlides={true}
                        spaceBetween={30}
                        navigation
                        //  pagination={{ clickable: true }}
                        breakpoints={{
                            640: {
                                width: 640,
                                slidesPerView: 1,
                            },
                            768: {
                                width: 500,
                                slidesPerView: 2,
                                margin: 0,
                            },
                            1024: {
                                width: 800,
                                slidesPerView: 2,
                                margin: 0,
                            },
                            1300: {
                                width: 1000,
                                slidesPerView: 2,
                                margin: 0,
                            },
                            1500: {
                                width: 800,
                                slidesPerView: 2,
                                margin: 0,
                            },
                        }}
                    >
                        <SwiperSlide className='swiper-slide'>
                            <div class="card text-center">
                                <div class="card-body">
                                    <h5 class="card-title">Guddu Kumar</h5>
                                    <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='swiper-slide'>
                            <div class="card text-center">
                                <div class="card-body">
                                    <h5 class="card-title">Guddu Kumar</h5>
                                    <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='swiper-slide'>
                            <div className='col-md-12'>
                                <div class="card text-center">
                                    <div class="card-body">
                                        <h5 class="card-title">Guddu Kumar</h5>
                                        <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='swiper-slide'>
                            <div className='col-md-12'>
                                <div class="card text-center">
                                    <div class="card-body">
                                        <h5 class="card-title">Guddu Kumar</h5>
                                        <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>
        <section className='second-slider'>
            <div className="row">
                <h1 className="replying-audiomeet">Relying on Audiomeets</h1>
                <Swiper
                    navigation={true}
                    // Autoplay={true}
                    slidesPerView={5}
                    spaceBetween={30}
                    modules={[Navigation, Autoplay]}
                    className="testimonial-audiomeet-slider">
                    <SwiperSlide className="swiper-slider">
                        <div class="logo">
                            <p>logo</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="logo">
                            <p>logo</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="logo">
                            <p >logo</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="logo">
                            <p >logo</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="logo">
                            <p >logo</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="logo">
                            <p >logo</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="logo">
                            <p >logo</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    </>
  );
};
export default Testimonial;
