import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './HomePage/HomePage';
import AboutUsPage from './AboutUs/AboutUsPage';
import FeaturePage from './Feature/FeaturePage';
import SolutionsPage from './Solutions/SolutionsPage';
import PartnersPage from './Partners/PartnersPage';
import SuccessStoriesPage from './SuccessStories/SuccessStoriesPage';
import Header from './HomePage/Header/Header';
import LiveStreamPage from './LiveStream/LiveStreamPage'
import TermsPage from './TermsCondition/TermsPage';
import PrivacyPolicy from './privacy-policy-folder/PrivacyPolicy';
import EducationSolution from './EducationSolution/EducationSolution';
import  HealthSolution from './HealthSolution/HealthSolution';
import RetailsPage from './Retails/RetailsPage'
import PlansPricingPage from './PlansPricing/PlansPricingPage';
import Procuct from './product/Procuct'
import Footer from './HomePage/Footer/Footer';


function App() {

  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutUsPage />} />
        <Route path='/feature' element={<FeaturePage />} />
        <Route path='/solution' element={<SolutionsPage />} />
        <Route path='/success-story' element={<SuccessStoriesPage />} />
        <Route path='/partners' element={<PartnersPage />} />
        <Route path='/LiveStreamPage' element={<LiveStreamPage />} />
        <Route path='/TermsPage' element={<TermsPage />} />
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/EducationSolution' element={<EducationSolution />} />
        <Route path='/HealthSolution' element={< HealthSolution />} />
        <Route path='/RetailsPage' element={< RetailsPage />} />
        <Route path='/PlansPricingPage' element={<PlansPricingPage/>} />
        <Route path='/Procuct' element={<Procuct/>} />
      </Routes>
      <Footer />

    </>
  );
}

export default App;

