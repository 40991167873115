import React from "react";
import "./Passionate.css";
import pen from "../imgs/pen.png";
import library from "../imgs/library.png";
import tv from "../imgs/tv.png";
import whitescreen from "../imgs/whitescreen.png";

const Passionate = () => {
  return (
    <>
      <section className="Passionate">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            <h1 className="Passionate_Heading mt-5">
              We’re Passionate About Education
            </h1>
            <p className="explore">
              Explore Agora’s featured education content
            </p>
            </div>
            </div>
            {/* 1st card */}
            <div className="row">
            <div className="col-lg-3 col-md-6 ">
              <div class="card mb-3">
                <img src={pen} className="img-passion" alt="..." />
                <div class="card-body">
                  <h1 >BLOG</h1>
                  <p >
                    How to keep students engaged with Synchronous Learning
                  </p>
                </div>
              </div>
            </div>
            {/* 2nd card */}
            <div className="col-lg-3 col-md-6">
              <div class="card mb-3">
                <img src={library} className="img-passion" alt="..." />
                <div class="card-body">
                  <h1 >EBOOK</h1>
                  <p >
                    Increase online students' engagement, retention and revenue.
                  </p>
                </div>
              </div>
            </div>
            {/* 3rd card */}
            <div className="col-lg-3 col-md-6">
              <div class="card mb-3">
                <img src={tv} className="img-passion" alt="..." />
                <div class="card-body">
                  <h1 >ON-DEMAND WEBINAR</h1>
                  <p >
                    Three strategies for Building a Highly Engaging Classroom
                  </p>
                </div>
              </div>
            </div>
            {/* 4th card */}
            <div className="col-lg-3 col-md-6">
              <div class="card mb-3">
                <img
                  src={whitescreen}
                  className="img-passion"
                  alt="..."
                />
                <div class="card-body">
                  <h1 >WHITEPAPER</h1>
                  <p >
                    Three strategies for Building a Highly Engaging Classroom
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

  
      
    </>
  );
};
export default Passionate;
