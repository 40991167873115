import React from "react";
import "./EffectiveSystem.css";
const EffectiveSystem = () => {
    return (
      <>
      <section className="EffectiveSystem">
        <div className="container">
            <div className="row">
            <div className="col-md-12">
            <div className="background-color">
              <p className=" pt-2">
                An effective system to Connect easily, privately, & securely to
                coordinate efficiently, as a team. We offer everything that you
                need for better and more meaningful team communication,
                establish connections, and facilitate better team operation for
                smooth interaction.
              </p>
             
              </div>
            </div>
            </div>
            <hr class="dashed-2"></hr>
        </div>
      </section>
      </>
    );
};
export default EffectiveSystem;