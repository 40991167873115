import React from "react";
import '../Virtual/virtual.css';
const Virtual = () => {
  return (
    <>
      <section className="Virtual">
        <div className="container">
          <div className="row">
            <h1 className="boundless">Boundless virtual education is here</h1>
            <div className="text-holder">
              <p >
                Break free from one-size-fits-all video conferencing solutions
                that ignore your students’ needs. Say goodbye to unstable
                connections, high latency, and scalability issues.
              </p>
              <p >
                Agora’s education solutions give you the building blocks to
                assemble a custom, branded virtual classroom at any scale,<br/> with
                a diverse set of engagement and collaboration tools that work
                reliably across the globe.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Virtual;
