import React from "react";
import "./Footer.css";
import Log from "../imgs/Log.png";
import a from "../imgs/a.png";
import ga from "../imgs/ga.png";
import instagram from "../imgs/instagram.png";
import facebook from "../imgs/facebook.png";
import twitter from "../imgs/twitter.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <section class="footer">
        <div className="container">
          <div className="row">

            <nav class="navbar  navbar-expand-lg ">
              <div class="container">
                <a class="navbar-brand" href="#"></a>
                <button class="navbar-toggler colr-icon" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                  <ul class="nav   footer-ul ">
                    <li class="nav-item active">
                    <Link to="" class="nav-link active" aria-current="page" >
                      HOME
                    </Link>
                    </li>
                    <li class="nav-item">
                    <Link to="/about" class="nav-link">
                      ABOUT US
                    </Link>
                    </li>
                    <li class="nav-item">
                    <Link to="/feature" class="nav-link">
                      FEATURES
                    </Link>
                    </li>
                    <li class="nav-item">
                    <Link to="/solution" class="nav-link">
                      SOLUTIONS
                    </Link>
                    </li>
                    <li class="nav-item">
                    <Link to="/TermsPage" class="nav-link">
                        TERMS & CONDITIONS
                      </Link>
                    </li>
                    <li class="nav-item">
                    <Link to="/PrivacyPolicy" class="nav-link">
                        PRIVACY & PRIVACY
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
          <div className="row mt-4">
            <div className="col-lg-3 mb-3">
              <img src={Log} className="logo img-fluid" alt="..." />
            </div>
            <div className="col-lg-3 ">
              <p className="a">
                <img src={a} alt="..." className="logo_img1 me-2"></img>
                <span>+91 7030750123</span>
              </p>
            </div>
            <div className="col-lg-3 ">
              <div className="d-flex ">
                <img src={ga} alt="..." className="logo_img2 me-2"></img>
                <span> dksspune03@gmail.com/dksspune03@gmail.com</span>
              </div>

            </div>
            <div className="col-lg-3">
              <div className="social-icon ms-lg-5 text-center">
                <span >Follow Us On:</span>
                <div className="icons">
                  <img src={instagram} alt="..." className="instagram me-2"></img>
                  <img src={facebook} alt="..." className="facebook me-2"></img>
                  <img src={twitter} alt="..." className="twiter me-2"></img>
                </div>

              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="copyright-holder text-center">
              <p className="reserved">© 2022 All Rights Reserved Terms Of Use | Privacy Policy Powered by Profcyma</p>
            </div>
          </div>



        </div>
      </section>
    </>
  );
};

export default Footer;