import React from "react";
import SuccessBanner from "./SuccessBanner/SuccessBanner";
  import SuccessStoriesCards from "./SuccessStoriesCards/SuccessStoriesCards";
import SuccessCard from "./success-card-folder/SuccessCard";
import Pagination from "../Partners/pagination-folder/Pagination";


const SuccessStoriesPage = () => {
  return (
    <div>
      <SuccessBanner />
      <SuccessStoriesCards/>
      <SuccessCard/>
      <Pagination/>
    </div>
  );
};

export default SuccessStoriesPage;
