import React from "react";
import Feature_Banner from "./Feature_Banner/Feature_Banner";
import PhoneBanner from "./PhoneBanner/PhoneBanner";
import Points from "./Points/Points";
import Customization from "./Customization/Customization";

const FeaturePage = () => {
  return (
    <div>
      <Feature_Banner />
      {/* <PhoneBanner /> */}
      <Points />
      <PhoneBanner />
      <Customization/>
    </div>
  );
};

export default FeaturePage;
