import React from 'react'
//import Header from './Header/Header';
 import AboutUsBanner from './AboutUsBanner/AboutUsBanner';
 import Mission from './Mission/Mission' ;
 import Cards from './Cards/Cards' ;
 import Hosting from './Hosting/Hosting' ;
 //import Footer2 from './Footer2/Footer2' ;

const AboutUsPage = () => {
  return (
    <div>
    {/* <Header/> */}
    {/* <Footer2/> */}
       <AboutUsBanner/>
      <Mission/> 
      <Cards/>
      <Hosting/>
      {/* <Footer2/> */}
    </div>
  )
}

export default AboutUsPage