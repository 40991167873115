import React from 'react'
import '../audio-folder/audio.css'
const Audio = () => {
    return (
        <>
            <section className="last-part ">
                <div className="container">
                    <div className="row">
                        <h1 className="experience">
                            Experience Audio meets education solutions in action.
                        </h1>
                        <p className="our-team">
                            Our team is here to walk you through the future of education
                        </p>
                        
                            <div className="button-schedule text-center mt-4 mb-3">
                                <button className='btn btn-dark'>Schedule Your Demo</button>
                            </div>
                      
                        <p className="explore_agoras">Explore Agora’s featured education content</p>
                    </div>
                </div>

            </section>

        </>
    )
}

export default Audio