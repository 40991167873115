import React from "react";
import "../PointsList/PointsList.css";
import icon from "../imgs/icon.png";
import rightclick from "../imgs/rightclick.png";

const PointsList = () => {
  return (
    <>
      <section className="PointsList">
        <div className="container">
          <div className="row icon">
            <div className="image-holder">
             <img src={icon}/>
             <div class="d-flex mt-3 right-holder">
                <div className="image-right">
                  <img src={rightclick} className="rightclick_img me-2" alt="..." />
                </div>
                <div className="text  mt-1">
                  <p>The audio meet is a feature-rich cloud phone system for
                    businesses of all sizes.</p>
                </div>
              </div>

              <div class="d-flex mt-3 customer">
                <div className="image-right">
                  <img src={rightclick} className="rightclick_img me-2" alt="..." />
                </div>
                <div className="text  mt-1">
                  <p>Customers who are using audio meet reports increased performance, engagement, reliability, and overall productivity of an organization. </p>
                </div>
              </div>

              <div class="d-flex mt-3 support">
                <div className="image-right">
                  <img src={rightclick} className="rightclick_img me-2" alt="..." />
                </div>
                <div className="text mt-1">
                  <p>Supporting the administrators and providing flexible learning options for everyone has never been easier.</p>
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PointsList;
