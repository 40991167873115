import React from 'react'
import LiveStreamBanner from './LiveStreamBanner/LiveStreamBanner';
import EduUseCases from './EduUseCases/EduUseCases';
import EduStream from './EduStream/EduStream';
import WhyAudio from "./WhyAudio/WhyAudio";




const LiveStreamPage = () => {
  return (
    <div>
      <LiveStreamBanner />
      <EduUseCases />
      <EduStream />
   
      <WhyAudio />
    </div>
  )
}

export default LiveStreamPage;