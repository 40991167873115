import React from 'react'
import '../success-card-folder/successcard.css';
import virdatta from '../imgs/Vidarbha.png'
import snopix from '../imgs/SINOPEX.png'
import green from '../imgs/Star.png'
import auto from '../imgs/AUTO.png'
import studio from '../imgs/studio.png'
import maharaja from '../imgs/maharaja.png'
import dental from '../imgs/dental.png'
import vesta from '../imgs/vesta.png'
const SuccessCard = () => {
  return (
    <>
      <section className='success-button'>
        <div className='container'>
          <div className='row'>
      
            <ul class="nav nav-pills mb-5 justify-content-center" id="pills-tab" role="tablist">
              <li class="nav-item   me-3" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Our Partners</button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                <div className='row'>
                <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={virdatta} className='virdatta'/></div>
                  <div class="card-body">
                    <h5 className='tittle'>VIDARBHA BASKET</h5>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={snopix} className='snopix'/></div>
                  <div class="card-body">
                  <h5 className='tittle'>SINOPEX</h5>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={green} className='grren'/></div>
                  <div class="card-body">
                  <h5 className='tittle'>STAR GREEEN</h5>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={auto} className='auto'/></div>
                  <div class="card-body">
                  <h5 className='tittle'>AUTO TOUCH</h5>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={dental} className='dental'/></div>
                  <div class="card-body">
                  <h5 className='tittle'>WHITE DENTAL</h5>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
              <div class="card-header text-center"><img src={studio} className='studio'/></div>
                  <div class="card-body">
                  <h5 className='tittle'>WOODIO ART STUDIO</h5>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={maharaja} className='maharaja'/></div>
                  <div class="card-body">

                  <h5 className='tittle'>MAHARAJA EXCHANGE</h5>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={vesta} className='vesta'/></div>
                  <div class="card-body">
                  <h5 className='tittle'>VESTA</h5>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
      
              </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SuccessCard