import React from "react";
import '../BestValue/BestValue.css';
import arrowicon from '../imgs/arrow.png'
const BestValue = () => {


  return (

    <>


      {/* planspricing 1st card */}
      <div className="row best-value">
        <div className="col-lg-4 col-md-6">

          <div class="accordion mb-3" id="accordionExample">
            <div class="accordion-item">
              {/* <h2 class="accordion-header" id="headingOne"> */}
              <div class="accordion-button card_pricing2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div className="text-details-icon">  Details</div>
                <div className="mt-3">
                <div className="title text-center ">
                  <h3>Basic</h3>
                </div>
                <div className="text ">
                  <h4>Free</h4>
                </div>
                <div className="button ">
                  <button className="btn btn-sign-up">Sign Up</button>
                </div>
                <div className="text ">
                  <p>Meetings<span>p to 40 minutes per meeting</span></p>
                  <p>100 Attendees<span>per meeting</span></p>
                  <p>Whiteboard<span>3 included</span></p>
                  <p>Team Chat</p>
                </div>
                <div className="text d-flex ">
                  <h5 className="px-3 pt-2">BETA</h5>
                  <p>Mail & Calendar</p>
                </div>
                <span className="mb-4">Client</span>
                </div>
              </div>
              {/* 
              </h2> */}
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body ">
                  <div className="text ">
                    <p>Meetings</p>
                    <ul>
                      <li>Host up to 100 participants</li>
                      <li>Unlimited meetings for up to 40 minutes </li>
                      <li>5 GB of cloud recording storage (per license) </li>
                      <li>Automated Captions</li>
                    </ul>
                  </div>
                  <div className="text ">
                    <p>Whiteboard</p>
                    <ul>
                      <li>3 editable boards with 25MB of cloud storage</li>
                    </ul>
                  </div>
                  <div className="text ">
                    <p>Team Chat</p>
                    <ul>
                      <li>Chats and channels for collaboration, file sharing, and more</li>
                    </ul>
                  </div>
                  <div className="text ">
                    <p>Mail & Calendar</p>
                    <ul>
                      <li>Mail and Calendar Client beta</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
        {/* planspricing 2nd card */}
        <div className="col-lg-4 col-md-6">

          <div class="accordion mb-3" id="accordionExample">
            <div class="accordion-item">
             <div class="accordion-button card_pricing2" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="true" aria-controls="collapsetwo">
                <div className="text-details-icon">  Details</div>
                <div className="">
                <div className="title text-center">
                  <p>₹2,400 savings /year/user</p>
                  <h3>Pro</h3>
                </div>
                <div className="text ">
                  <h4>₹13,200/year/user</h4>
                </div>
                <div className="button ">
                  <button className="btn btn-buy">Buy Now</button>
                </div>
                <div className="text ">
                  <p>Meetings<span>p to 40 minutes per meeting</span></p>
                  <p>100 Attendees<span>per meeting</span></p>
                  <p>Whiteboard<span>3 included</span></p>
                  <p>Team Chat</p>
                </div>
                <div className="text d-flex ">
                  <h5 className="px-3 pt-2">BETA</h5>
                  <p>Mail & Calendar</p>
                </div>
                <div className="text ">
                  <span>Client</span>
                  <h4>Cloud Storage 5GB</h4>
                </div>
                <div className="text d-flex">
                  <h5 className="px-3 pt-2">NEW</h5>
                  <p>Essential Apps</p>
                </div>
                <div className="text ">
                  <span>Free premium apps for 1 year (terms apply)</span>
                </div>
              </div>
              </div>
              {/* 
              </h2> */}
              <div id="collapsetwo" class="accordion-collapse collapse" aria-labelledby="headingtwo" data-bs-parent="#accordionExample">
                <div class="accordion-body ">
                  <div className="text">
                    <p>Meetings</p>
                    <ul>
                      <li>Host up to 100 participants</li>
                      <li>Unlimited meetings for up to 40 minutes </li>
                      <li>5 GB of cloud recording storage (per license) </li>
                      <li>Automated Captions</li>
                    </ul>
                  </div>
                  <div className="text">
                    <p>Whiteboard</p>
                    <ul>
                      <li>3 editable boards with 25MB of cloud storage</li>
                    </ul>
                  </div>
                  <div className="text">
                    <p>Team Chat</p>
                    <ul>
                      <li>Chats and channels for collaboration, file sharing, and more</li>
                    </ul>
                  </div>
                  <div className="text ">
                    <p>Mail & Calendar</p>
                    <ul>
                      <li>Mail and Calendar Client beta</li>
                    </ul>
                  </div>
                  <div className="text d-flex ">
                    <h5 className="px-3 pt-2">NEW</h5>
                    <p>Essential Apps</p>
                  </div>
                  <div className="text ">
                    <ul>
                      <li>Free premium apps for 1 year (terms apply)</li>
                      <li>Increase team collaboration and productivity</li>
                    </ul>
                  </div>
                  <p className="learn-more">Learn More</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* planspricing 3rd card */}
      
        <div className="col-lg-4 col-md-6">
          <div class="accordion mb-3" id="accordionExample">
            <div class="accordion-item">
            {/* <div className="value-offer text-center">
                <p>BEST VALUE</p>
              </div> */}
              <div class="accordion-button card_pricing2" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree" aria-expanded="true" aria-controls="collapsethree">
                <div className="text-details-icon">  Details</div>
                <div className="">
                <div className="title text-center">
                  <h3>Business</h3>
                </div>
                <div className="text ">
                  <h4>₹18,000/year/user</h4>
                </div>
                <div className="button ">
                  <button className="btn btn-buy">Buy Now</button>
                </div>
                <div className="text ">
                  <p>Meetings</p>
                  <ul>
                    <li>Host meetings up to 300 participants</li>
                    <li>Unlimited meetings for up to 30 hours per meeting </li>
                    <li>5 GB of cloud recording storage (per license) </li>
                    <li>Automated Captions</li>
                  </ul>
                </div>
                <div className="text  ">
                  <p>Whiteboard</p>
                  <ul>
                    <li>Unlimited boards</li>
                  </ul>
                </div>
                <div className="text ">
                  <p>Team Chat</p>
                  <ul>
                    <li>Chats and channels for collaboration, file sharing, and more</li>
                  </ul>
                </div>
                <div className="text d-flex ">
                  <h5 className="px-3 pt-2">BETA</h5>
                  <p>Mail & Calendar</p>
                </div>
                <div className="text ">
                  <span>Client</span>
                  <h4>Cloud Storage 5GB</h4>
                </div>
                <div className="text d-flex ">
                  <h5 className="px-3 pt-2">NEW</h5>
                  <p>Essential Apps</p>
                </div>
                <div className="text  ">
                  <p>Whiteboard</p>
                  <ul>
                    <li>Free premium apps for 1 year (terms apply)</li>
                    <li>Increase team collaboration and productivity</li>
                  </ul>
                </div>
              </div>
              </div>
              {/* 
              </h2> */}
              <div id="collapsethree" class="accordion-collapse collapse" aria-labelledby="headingthree" data-bs-parent="#accordionExample">
                <div class="accordion-body ">
                  <div className="text">
                    <p>Meetings</p>
                    <ul>
                      <li>Host meetings up to 300 participants</li>
                      <li>Unlimited meetings for up to 30 hours per meeting </li>
                      <li>5 GB of cloud recording storage (per license) </li>
                      <li>Automated Captions</li>
                    </ul>
                  </div>
                  <div className="text">
                    <p>Whiteboard</p>
                    <ul>
                      <li>Unlimited boards</li>
                    </ul>
                  </div>
                  <div className="text">
                    <p>Team Chat</p>
                    <ul>
                      <li>Chats and channels for collaboration, file sharing, and more</li>
                    </ul>
                  </div>
                  <div className="text ">
                    <p>Mail & Calendar</p>
                    <ul>
                      <li>Mail and Calendar Client & Service beta</li>
                    </ul>
                  </div>
                  <div className="text d-flex ">
                    <h5 className="px-3 pt-2">NEW</h5>
                    <p>Essential Apps</p>
                  </div>
                  <div className="text ">
                    <ul>
                      <li>Free premium apps for 1 year (terms apply)</li>
                      <li>Increase team collaboration and productivity</li>
                    </ul>
                  </div>
                  <p className="learn-more">Learn More</p>
                  <div className="text ">
                    <p> Extras</p>
                    <ul>
                      <li>Single sign-on</li>
                      <li>Managed domains</li>
                      <li>Company branding</li>
                    </ul>
                  </div>
                  <div className="starting-offer">
                    <p>*Starting at 10 licenses for<br /> ₹180,000/year</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </>
  );
};
export default BestValue;
