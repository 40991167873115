import React from "react";
import "../Mission/Mission.css";
const Mission = () => {
  return (
    <>
      <section className="Mission">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-12">
            <div className="mission">
              <div className="card mb-3">
                <div className="card_text">
                  <h5 className="Mission_heading pt-4 px-3">Mission</h5>
                  <p className="communication py-2 px-3">
                    Make communication and team collaboration seamless and
                    secure.
                  </p>
                </div>
              </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
            <div className="vision">
              <div className="card mb-3">
                <div className="card_text mb-3">
                  <h5 className="Mission_heading pt-4 px-3">Vision</h5>
                  <p className="communication py-2 px-3">
                    To help people work, communicate and share at ease.
                  </p>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Mission;
