import React from "react";
import '../Header/Header.css';
import AUDIO from "../imgs/AUDIO.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <section className="Header">
        <div className="container-fluid">
          <div className="row">
            {/* white part */}
            <div className="col-md-12 white-back">
              <img src={AUDIO} className="logo ms-5" alt="..." />
            </div>

            {/* black part */}
            <div className="col-md-12 black-back">
              <div className="text-end mt-5 me-5">
                <Link to="/PlansPricingPage" className="plan">Plans & Pricing</Link>
              </div>
            </div>
            {/* Green Box */}
            {/* <div className="green-box"> */}
            <nav class="navbar  navbar-expand-lg">
              <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <Link to="" class="nav-link " aria-current="page" >
                        HOME
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/about" class="nav-link ">
                        ABOUT US
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/feature" class="nav-link">
                        FEATURES
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/solution" class="nav-link">
                        SOLUTIONS
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/partners" class="nav-link">
                        PARTNERS
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/success-story" class="nav-link">
                        SUCCESS STORIES
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/Procuct" class="nav-link">
                      PRODUCT
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            {/* </div> */}

          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
