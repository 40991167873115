import React from "react";
import "./SolutionsBanner.css";
// import Solutions_Banner from "../imgs/Solutions_Banner.png";
import solution_img1 from "../imgs/solution_img1.png";
import solution_img2 from "../imgs/solution_img2.png";
import { Link } from "react-router-dom";


const SolutionBanner = () => {
    return (
      <>
      <section className="SolutionBanner">
        <div className="container-fluid">
          <div className="row ">
          <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
              <div className="">
                <h1 className="solution">Solutions</h1>
              </div>
              <div className="border-green mt-5"></div>
             <Link to='/PlansPricingPage'> <div className="btn btn-green pt-2">Plans & Pricing</div></Link>
            </div>
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5">
              <div className="banner-holder mx-auto">
                <img src={solution_img1} alt="..." className="img-fluid img1" />
                <img src={solution_img2} alt="..." className="img-fluid img2" />
              </div>
            </div>
          </div>
        </div>
      </section>
      
          </>
    );
};
export default SolutionBanner;
