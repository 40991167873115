import React from 'react'
import '../treat-patients/treatpatients.css';
import leftmeet from "../imgs/hii.png";
import tension from "../imgs/sad-girl.png";
import circle from '../imgs/circle-img.png'
import groupimg from '../imgs/group-img.png' 
const TreatPatients = () => {
    return (
        <>
            <section className="treat-patients">
                <div className="container">
                    <div className="row">
                        <h1 className="Engage_Heading my-3">
                            Treat Patients. Teach Medicine. Share Knowledge.
                        </h1>
                    </div>
                    {/* 1st row */}
                    <div class="row mt-5 mb-5">
                        <div className=" col-md-4">
                            <div className="engage_content">
                                <h1 className="">Virtual Consultations</h1>
                            </div>
                        </div>
                        <div className="col-md-8 border-gray mt-3"></div>
                        <div className="col-lg-6 col-md-12">
                            <div className="paragraph-holder">
                                <p>Create intuitive solutions for virtual medical care and deliver high-quality remote consultations. Save time for patients and doctors by providing telehealth services anywhere in the world.  <br/>
                                 Even rural communities with low internet speeds can easily connect in real-time. Communicate with confidence using Audio Meets' intelligent and secure network that prevents lag or dropped calls.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div className="image-holder">
                                <img src={leftmeet} className="img-fluid rightmeet" alt="..." />
                            </div>
                        </div>
                    </div>
                    <div class="row mental-health">
                        <div className=" col-md-8 border-gray mt-3"></div>
                        <div className=" col-md-4">
                            <div className="engage_content">
                                <h1 className="">Mental Healthcare</h1>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div className='circle-img '>
                                <img src={circle} />
                                <div className="image-holder">
                                    <img src={tension} className="img-fluid rightmeet" alt="..." />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="paragraph-holder pt-5">
                                <p> Deliver virtual therapy sessions and enable quality mental counseling from the comfort of patient’s homes.
                                    <br/>
                                    See how Talkspace is revolutionizing mental health services with Audiomeets' real-time voice and video integrations.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row mental-health">
                        <div className=" col-md-4">
                            <div className="engage_content">
                                <h1 className="">Remote Patient Monitoring</h1>
                            </div>
                        </div>
                        <div className="col-md-8 border-gray mt-3"></div>
                        <div className="col-lg-6 col-md-12">
                            <div className="paragraph-holder ">
                                <p>Connect remote monitoring systems to trigger instant support calls between patients and physicians. Provide quick access to care in areas outside of network providers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div className="image-holder">
                                <img src={leftmeet} className="img-fluid rightmeet" alt="..." />
                            </div>
                        </div>
                    </div>
                    <div class="row mental-health">
                        <div className=" col-md-8 border-gray mt-3"></div>
                        <div className="col-md-4">
                            <div className="engage_content">
                                <h1 className="">Medical Training & Events</h1>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div className='circle-img'>
                                <img src={circle} />
                                <div className="image-holder">
                                    <img src={groupimg} className="img-fluid rightmeet" alt="..." />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="paragraph-holder pt-5">
                                <p >
                                    Allow customers to talk to an expert over video to simplify communication and customer service questions.
                                </p>
                                <p >
                                    Resolve issues or take orders in real time, without worrying about connection issues. Ensure high quality and reliable connections with customers around the world and on any device and platform.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row mental-health">
                        <div className=" col-md-4">
                            <div className="engage_content">
                                <h1 className="">Therapy & Fitness</h1>
                            </div>
                        </div>
                        <div className="col-md-8 border-gray mt-3"></div>
                        <div className="col-lg-6 col-md-12">
                            <div className="paragraph-holder ">
                                <p>Coach live 1:1 sessions, group classes, or host individual therapy sessions. Use high-quality video to guide clients on their form and demonstrate technique.<br/><br/> 
                                See how MixPose uses Audiomeets to deliver an in-studio yoga experience, virtually.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div className="image-holder">
                                <img src={leftmeet} className="img-fluid rightmeet" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
        </>
    )
}

export default TreatPatients