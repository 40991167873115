import React from 'react'
import '../pagination-folder/pagination.css'
const Pagination = () => {
    return (
        <>
            <section className='pagination-partner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <nav aria-label="Page navigation ">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link border-gray" href="#">Previous Page</a></li>
                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                   <li class="page-item"><a class="page-link border-gray" href="#">Next Page</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pagination