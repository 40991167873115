import React from "react";
import "./Points.css";

const Points = () => {
  return (
    <>
      <section className="points">
        <div className="container">
          <div className="row bg-green">
            <div className="col-lg-6 col-md-12 mb-2">
              <div className="d-flex">
              <div className="green-box-number mt-5">
                <div className="number-heading">
                  <h2>1</h2>
                </div>
              </div>
              <div className="no_plate ms-2">
                <h4 className="simple mt-5">Simple and user-friendly VoIP phone system</h4>
                <p className="features">
                  The same experience of Audio meets Meetings powers Audio meets
                  Phone’s enterprise-class VoIP features.
                </p>
              </div>
            </div>
            </div>
            <div className="col-lg-6 col-md-12 mb-2">
            <div className="d-flex">
              <div className="green-box-number mt-5">
                <div className="number-heading">
                  <h2>2</h2>
                </div>
              </div>
              <div className="no_plate ms-2">
                <h4 className="simple mt-5">System for customizing business processes</h4>
                <p className="features">
                  The audio meeting enables you to work according to your need
                  and helps solve your unique business problems.
                </p>
              </div>
            </div>
            </div>
            <div className="col-lg-6 col-md-12 mb-3">
            <div className="d-flex">
              <div className="green-box-number mt-3">
                <div className="number-heading">
                  <h2>3</h2>
                </div>
              </div>
              <div className="no_plate ms-2 ">
                <h4 className="simple mt-3">Secured and scalable collaborations</h4>
                <p className="features">
                  Audio meet Phone was built with reliability and geographic
                  redundancy for businesses of all sizes.
                </p>
              </div>
            </div>
            </div>
            <div className="col-lg-6 col-md-12  mb-3">
            <div className="d-flex">
              <div className="green-box-number mt-3">
                <div className="number-heading">
                  <h2>4</h2>
                </div>
              </div>
              <div className="no_plate ms-2">
                <h4 className="simple mt-3">Ideate, organize, and act</h4>
                <p className="features">
                  Imagine & create a complete idea of the meeting then organize
                  the meeting and then execute the meeting.
                </p>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Points;
