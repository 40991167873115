import React from "react";
import "../PartnerBanner/PartnerBanner.css";

const PartnerBanner = () => {
  return (
    <>
      <section className="partners-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
       
              <h1 className="partner_heading">Audio Meets<br /> Partners Gallery</h1>
              <p className="text">
                Whether you’re looking for a turnkey solution, or the technology
                and design / build expertise to help you execute your vision,
                Agora’s partners are here to help.
              </p>
            </div>
            <div className="col-lg-6 col-md-12"></div>
          </div>
        </div>
      </section>


    </>
  );
};

export default PartnerBanner;
