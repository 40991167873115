import React from 'react'
import './button.css'
import virdatta from '../imgs/Vidarbha.png'
import snopix from '../imgs/SINOPEX.png'
import green from '../imgs/Star.png'
import auto from '../imgs/AUTO.png'
import netralaya from '../imgs/dudhbhate.png'
import maharaja from '../imgs/maharaja.png'
import dental from '../imgs/dental.png'
import vesta from '../imgs/vesta.png'
const ButtonPartner = () => {
  return (
    <>
      <section className='all-button'> 
        <div className='container'>
          <div className='row'>
          <div class="style1"></div>
            <ul class="nav nav-pills mb-5 justify-content-center mt-3" id="pills-tab" role="tablist">
              <li class="nav-item   me-3" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">All Partners</button>
              </li>
              <li class="nav-item me-3" role="presentation">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Educational</button>
              </li>
              <li class="nav-item me-3" role="presentation">
                <button class="nav-link" id="pills-ecommerce-tab" data-bs-toggle="pill" data-bs-target="#pills-ecommerce" type="button" role="tab" aria-controls="pills-ecommerce" aria-selected="false">E-Commerce</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-health-tab" data-bs-toggle="pill" data-bs-target="#pills-health" type="button" role="tab" aria-controls="pills-health" aria-selected="false">Health Care</button>
              </li>
              <li class="nav-item me-3" role="presentation">
                <button class="nav-link" id="pills-Real-tab" data-bs-toggle="pill" data-bs-target="#pills-Real" type="button" role="tab" aria-controls="pills-Real" aria-selected="false">Real Estate</button>
              </li>
              <li class="nav-item me-3" role="presentation">
                <button class="nav-link" id="pills-Technology-tab" data-bs-toggle="pill" data-bs-target="#pills-Technology" type="button" role="tab" aria-controls="pills-Technology" aria-selected="false">Technology</button>
              </li>
              <li class="nav-item me-3" role="presentation">
                <button class="nav-link" id="pills-Technology-tab" data-bs-toggle="pill" data-bs-target="#pills-Technology" type="button" role="tab" aria-controls="pills-Technology" aria-selected="false">Technology</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-Technology-tab" data-bs-toggle="pill" data-bs-target="#pills-Technology" type="button" role="tab" aria-controls="pills-Technology" aria-selected="false">Technology</button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                <div className='row'>
                <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={virdatta} className='virdatta'/></div>
                  <div class="card-body">
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={snopix} className='snopix'/></div>
                  <div class="card-body">
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={green} className='grren'/></div>
                  <div class="card-body">
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={auto} className='auto'/></div>
                  <div class="card-body">
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={netralaya} className='bhate'/></div>
                  <div class="card-body">
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={maharaja} className='maharaja'/></div>
                  <div class="card-body">
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={dental} className='dental'/></div>
                  <div class="card-body">
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div class="card  mb-3">
                  <div class="card-header text-center"><img src={vesta} className='vesta'/></div>
                  <div class="card-body">
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
      
              </div>
              </div>
              <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">...</div>
              <div class="tab-pane fade" id="pillsecommerce" role="tabpanel" aria-labelledby="pillsecommerce-tab" tabindex="0">...</div>
              <div class="tab-pane fade" id="pills-disabled" role="tabpanel" aria-labelledby="pills-disabled-tab" tabindex="0">...</div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ButtonPartner