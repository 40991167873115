import React from 'react'
import '../delight-audience/delightaudience.css'
import leftmeet from "../imgs/microphone.png";
import tension from "../imgs/plant-1.png";
import circle from '../imgs/circle.png'
import groupimg from '../imgs/phone-1.png'
const DelightAudience = () => {
    return (
        <>
            <section className="delight-audience">
                <div className="container">
                    <div className="row">
                        <div className='heading-holder'>
                        <p>USE CASES</p>
                        <h1 className="Engage_Heading my-3">
                           Delight Audiences with Fast, Hyper-Engaging Live Shopping
                        </h1>
                        </div>
                      </div>
                    {/* 1st row */}

                    <div class="row mt-5 mb-5">
                        <div className=" col-md-4">
                            <div className="engage_content">
                                <h1 className="">Live Auctions</h1>
                            </div>
                        </div>
                        <div className="col-md-8 border-gray mt-3"></div>
                        <div className="col-lg-6 col-md-12">
                            <div className="paragraph-holder">
                                <p> Shorten the distance between talented influencers and completed sale transactions. Give content creators easy tools to showcase specialties or high-value items and create urgency-enabled “buy it now” moments with live bidding.  <br/>
                                Ultra-low latency video and real-time synced data ensure audience bids and social interaction are natural and trusted. </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div className="image-holder">
                                <img src={leftmeet} className="img-fluid rightmeet" alt="..." />
                            </div>
                        </div>
                    </div>
                    <div class="row mental-health">
                        <div className=" col-md-7 border-gray mt-3"></div>
                        <div className=" col-md-5">
                            <div className="engage_content">
                                <h1 className="">Shoppable live streams</h1>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div className='circle-img '>
                                <img src={circle} />
                                <div className="image-holder">
                                    <img src={tension} className="img-fluid rightmeet" alt="..." />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="paragraph-holder pt-5">
                                <p>
                                    Whether you’re showing new cosmetics and makeup techniques, the latest in athletic clothing, or tools to bake the perfect cupcake—spread the word live.
                                    <br/>
                                    Let hosts use interactive live streams to create an immersive environment, such as a dinner party with fancy outfits and décor to showcase products in a believable setting. Add audience chat, polling and controls to allow customers to talk in real-time.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row mental-health">
                        <div className=" col-md-5">
                            <div className="engage_content">
                                <h1 className="">Personal Shoppers & Shop Together</h1>
                            </div>
                        </div>
                        <div className="col-md-7 border-gray mt-3"></div>
                        <div className="col-lg-6 col-md-12">
                            <div className="paragraph-holder ">
                                <p>Socialize buying decisions with one-on-one or one-to-few sessions on how to accessorize with a personal shopper. </p>
                                <p>For example, a personal shopper can conduct a live video call with a customer to suggest clothing items and ways to wear them for a special occasion. Alternately, a group video chat allows a mother not to miss her daughter’s wedding fitting.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div className="image-holder">
                                <img src={leftmeet} className="img-fluid rightmeet" alt="..." />
                            </div>
                        </div>
                    </div>
                    <div class="row mental-health">
                        <div className=" col-md-8 border-gray mt-3"></div>
                        <div className="col-md-4">
                            <div className="engage_content">
                                <h1 className="">Video Chat Customer Service</h1>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div className='circle-img'>
                                <img src={circle} />
                                <div className="image-holder">
                                    <img src={groupimg} className="img-fluid rightmeet" alt="..." />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="paragraph-holder pt-5">
                                <p >
                                    Allow customers to talk to an expert over video to simplify communication and customer service questions.
                                </p>
                                <p >
                                    Resolve issues or take orders in real time, without worrying about connection issues. Ensure high quality and reliable connections with customers around the world and on any device and platform.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DelightAudience