import React from "react";
import "./EduUseCases.css";
const EduUseCases = () => {
  return (
    <>
      <section className="EduCases">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="heading-holder">
              <h4 className="my-3">USE CASES</h4>
              <h1  className="my-3">Stream audio to a worldwide audience</h1>
              <p  className="my-3">
                Easily integrate live audio conversations into websites and apps
                with Agora’s Live Audio Streaming. Whether 100 or 1 million users
                join the stream, our fast initial rendering and channel switching,
                adaptable audio bitrate, and sophisticated algorithms manage
                scaling, so you don’t have to.
              </p>
            </div>
          </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default EduUseCases;
