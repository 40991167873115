import React from "react";
import "./WhyAudio.css";

const Audio = () => {
  return (
    <>
      <section className="Audio">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="text-holder">
              <h3 className="my-5">WHY AUDIOMEETS</h3>
              <h1 className="py-4">You get all the above plus the power of Agora’s <br/> Real-Time Engagement Platform</h1>
              <h3 className="py-4">With an intelligent global network, optimizations for mobile devices,<br/> cross-platform SDKs, and developer-centric building <br/> blocks, why would you choose anyone else?</h3>
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Audio