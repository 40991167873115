import React from 'react'
import './faq.css';
import quesimg from '../imgs/question.png';
const Faq = () => {
    return (
        <>
        
                <div className='row faq'>
                    <div className='col-lg-12'>
                        <div className='heading-holder text-center'>
                            <h2>FAQs</h2>
                        </div>
                    <div class="accordion" id="accordionExample">
                        <div className='d-flex'>
                            <div className='ques-img mt-3 me-3'>
                                <img src={quesimg} />
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What is a Licensed user and what is a Participant?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>A licensed user can host an unlimited number of meetings. However if multiple users have the need to schedule separate meetings, you will need additional meeting licenses per each user.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='ques-img mt-3 me-3'>
                                <img src={quesimg} />
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How many people can use one Audio Meets Meetings license?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    <p>A licensed user can host an unlimited number of meetings. However if multiple users have the need to schedule separate meetings, you will need additional meeting licenses per each user.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='ques-img mt-3 me-3'>
                                <img src={quesimg} />
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        How many participants can join the meeting?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>A licensed user can host an unlimited number of meetings. However if multiple users have the need to schedule separate meetings, you will need additional meeting licenses per each user.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
           
        </>
    )
}

export default Faq