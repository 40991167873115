import React from "react";
import "./Feature_Banner.css";
// import HomeBanner from "../imgs/HomeBanner.png";
import Bannerimg1 from "../imgs/Bannerimg1.png";
import Bannerimg2 from "../imgs/Bannerimg2.png";
import { Link } from "react-router-dom";
const Feature = () => {
    return (
      <>
        
      <section className="FeatureBanner">
        <div className="container-fluid">
          <div className="row banner-row">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
              <div className="">
                <h1 className="features">Features</h1>
              </div>
              <div className="border-green mt-5"></div>
              <Link to='/PlansPricingPage'><div className="btn btn-green pt-2">Plans & Pricing</div></Link>
            </div>
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5">
              <div className="banner-holder mx-auto">
                <img src={Bannerimg1} alt="..." className="img-fluid img1" />
                <img src={Bannerimg2} alt="..." className="img-fluid img2" />
              </div>
            </div>
          </div>
        </div>
      </section>
            </>
    );
};
export default Feature; 