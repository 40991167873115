import React from 'react'
import SolutionsBanner from '../Solutions/SolutionsBanner/SolutionsBannner' ;
import Industries from './Industries/Industries';
import PointsList from './PointsList/PointsList' ;
// import Solutions from './SolutionsBanner/SolutionsBanner' ;
import Usage from './Usage/Usage' ;

const SolutionsPage = () => {
  return (
    <div>
       <SolutionsBanner/>
      <Industries/>
      <Usage/>
      <PointsList/>
      </div>
  )
}

export default SolutionsPage;