import React from "react";
import "./EduStream.css";
import circle from '../imgs/circle-img.png'
import groupimg from '../imgs/Mobile.png'
import podcast from  '../imgs/paudcast.png'
import laptop from '../imgs/lapi.png'
import tablet from '../imgs/tablet.png'
const EduStream = () => {
  return (
    <>
      <section className='lives-button'>
        <div className='container'>
          <div className='row'>
            <div class="style1"></div>
            <ul class="nav nav-pills mb-5 justify-content-center mt-3" id="pills-tab" role="tablist">
              <li class="nav-item   me-3" role="presentation">
                <button class="nav-link active" id="pills-Live-tab" data-bs-toggle="pill" data-bs-target="#pills-Live" type="button" role="tab" aria-controls="pills-Live" aria-selected="true">Live Cast</button>
              </li>
              <li class="nav-item me-3" role="presentation">
                <button class="nav-link" id="pills-Online-tab" data-bs-toggle="pill" data-bs-target="#pills-Online" type="button" role="tab" aria-controls="pills-Online" aria-selected="false">Online Audio Classroom</button>
              </li>
              <li class="nav-item me-3" role="presentation">
                <button class="nav-link" id="pills-Event-tab" data-bs-toggle="pill" data-bs-target="#pills-Event" type="button" role="tab" aria-controls="pills-Event" aria-selected="false">Event Commentary</button>
              </li>

            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-Live" role="tabpanel" aria-labelledby="pills-Live-tab" tabindex="0">
                <div className="row">
                  <div className="heading-holder text-center  my-3">
                    <h2>Built for EducStream live podcasts to a worldwide audience</h2>
                  </div>
                  <div className="col-md-6">
                    <div className="paragraph-holder my-3">
                      <h3>Whether it’s a hot take with a celebrity or trending topics with tech executives, allow your audience to drop in on live discussions. </h3>
                      <h3>Audiomeets’ interactive audio streaming ensures your live podcast streams smoothly to anyone, on any device, anywhere in the world. </h3>
                    </div>
                    <div className="text-holder my-4">
                      <h3>THE AUDIOMEETS PRODUCTS THAT MAKE THIS POSSIBLE:</h3>
                      <div className="d-flex my-4">
                        <p>Voice Calling</p>
                        <div className="streaming ">
                          <p className="ps-5">Interactive Live Streaming</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className='circle-img'>
                      <img src={circle} />
                      <div className="image-holder">
                        <img src={groupimg} className="img-fluid rightmeet" alt="..." />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="heading-holder  mt-5">
                    <p className="text-center"> Audio meets</p>
                    <h2>Every feature you need for interactive audio streaming</h2>
                  </div>
                  <div class="row mt-5">
                    <div className=" col-md-4">
                      <div className="engage_content">
                        <h1 className="">Outstanding Audio</h1>
                      </div>
                    </div>
                    <div className="col-md-8 border-gray mt-3"></div>
                    <div className="col-lg-6 col-md-12">
                      <div className="paragraph-holder">
                        <h5>Full Bandwidth Capture </h5>
                        <p>
                        Full Bandwidth Capture A 48kHz (sampling rate) full-sound bandwidth capture provides the most natural audio reproduction for podcasts, music rooms, KTV, and other uses where audio quality is of critical importance. Stream audio up to 192kbps to reproduce the original audio source in high fidelity.
                       </p>
                       <h5>Fast initial rendering and channel switching </h5>
                        <p>
                        Audiomeets’ sub-second initial audio rendering and channel-switching time creates a seamless experience for media listeners.
                       </p>
                       <h5>Adaptable audio bitrate </h5>
                        <p>
                        Select an audio profile with a bitrate of 18kbps to 192kbps to provide the appropriate quality for your content needs.
                       </p>
                       <h5>Smooth experience </h5>
                        <p>
                        Audiomeets’ sophisticated network algorithms minimize latency and packet loss for a smooth, stutter-free experience without interruptions.
                       </p>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                      <div className="image-holder">
                        <img src={podcast} className="img-fluid podcast" alt="..." />
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div className=" col-md-8 border-gray mt-3"></div>
                    <div className=" col-md-4">
                      <div className="engage_content">
                        <h1 className="">Customizable Options</h1>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                      <div className="image-holder">
                        <img src={laptop} className="img-fluid laptop" alt="..." />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="paragraph-holder">
                      <h5>Cross-channel co-hosts </h5>
                        <p>
                        Create a competition or co-host event with hosts from up to four channels in an audio stream, perfect for live streaming social gatherings jointly or hosting competitions between cooks, DJs, or performance artists in different virtual rooms.
                       </p>
                       <h5>Voice effects  </h5>
                        <p>
                        Make streams fun and engaging with a range of voice effects. From sound mixing to sound reverb, users can change the way their voices sound to match their moods, characters they’re playing, or just satisfy a whim.
                       </p>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div className=" col-md-4">
                      <div className="engage_content">
                        <h1 className="">Versatile Controls</h1>
                      </div>
                    </div>
                    <div className=" col-md-8 border-gray mt-3"></div>
                    <div className="col-lg-6 col-md-12">
                      <div className="paragraph-holder">
                         <h5>AI-powered noise cancellation</h5>
                        <p>
                        Using automatic echo cancellation, automatic gain control, automatic noise suppression, and an AI-powered noise cancellation algorithm, Agora’s platform adapts to variant acoustic conditions to remove ambient and distracting noises, ensuring voices come through crystal clear.
                       </p>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                      <div className="image-holder">
                        <img src={tablet} className="img-fluid control" alt="..." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="pills-Online" role="tabpanel" aria-labelledby="pills-Online-tab" tabindex="0">
              <div className="row">
                  <div className="heading-holder text-center  my-3">
                    <h2>Create an interactive, online audio classroom</h2>
                  </div>
                  <div className="col-md-6">
                    <div className="paragraph-holder my-3">
                      <h3>Provide an ultra-low latency classroom interactive experience from anywhere with Audio Meets’s Live Audio Streaming. Audio Meets’s intelligent routing with our SD-RTN™ network ensures audio streams in real time without stutter or delay, so educators can teach and students can learn effectively. </h3>
                    </div>
                    <div className="text-holder my-4">
                      <h3>THE AUDIOMEETS PRODUCTS THAT MAKE THIS POSSIBLE:</h3>
                      <div className="d-flex my-4">
                        <p>Voice Calling</p>
                        <div className="streaming ">
                          <p className="ps-3">Interactive Live Streaming</p>
                        </div>
                        <div className="streaming ">
                          <p className="ps-3">Signaling</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className='circle-img'>
                      <img src={circle} />
                      <div className="image-holder">
                        <img src={groupimg} className="img-fluid rightmeet" alt="..." />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="heading-holder  mt-5">
                    <p className="text-center"> FEATURES</p>
                    <h2>Every feature you need for interactive audio streaming</h2>
                  </div>
                  <div class="row mt-5">
                    <div className=" col-md-4">
                      <div className="engage_content">
                        <h1 className="">Outstanding Audio</h1>
                      </div>
                    </div>
                    <div className="col-md-8 border-gray mt-3"></div>
                    <div className="col-lg-6 col-md-12">
                      <div className="paragraph-holder">
                        <h5>Full Bandwidth Capture </h5>
                        <p>
                        Full Bandwidth Capture A 48kHz (sampling rate) full-sound bandwidth capture provides the most natural audio reproduction for podcasts, music rooms, KTV, and other uses where audio quality is of critical importance. Stream audio up to 192kbps to reproduce the original audio source in high fidelity.
                       </p>
                       <h5>Fast initial rendering and channel switching </h5>
                        <p>
                        Audiomeets’ sub-second initial audio rendering and channel-switching time creates a seamless experience for media listeners.
                       </p>
                       <h5>Adaptable audio bitrate </h5>
                        <p>
                        Select an audio profile with a bitrate of 18kbps to 192kbps to provide the appropriate quality for your content needs.
                       </p>
                       <h5>Smooth experience </h5>
                        <p>
                        Audiomeets’ sophisticated network algorithms minimize latency and packet loss for a smooth, stutter-free experience without interruptions.
                       </p>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                      <div className="image-holder">
                        <img src={podcast} className="img-fluid podcast" alt="..." />
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div className=" col-md-8 border-gray mt-3"></div>
                    <div className=" col-md-4">
                      <div className="engage_content">
                        <h1 className="">Customizable Options</h1>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                      <div className="image-holder">
                        <img src={laptop} className="img-fluid laptop" alt="..." />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="paragraph-holder">
                      <h5>Cross-channel co-hosts </h5>
                        <p>
                        Create a competition or co-host event with hosts from up to four channels in an audio stream, perfect for live streaming social gatherings jointly or hosting competitions between cooks, DJs, or performance artists in different virtual rooms.
                       </p>
                       <h5>Voice effects  </h5>
                        <p>
                        Make streams fun and engaging with a range of voice effects. From sound mixing to sound reverb, users can change the way their voices sound to match their moods, characters they’re playing, or just satisfy a whim.
                       </p>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div className=" col-md-4">
                      <div className="engage_content">
                        <h1 className="">Versatile Controls</h1>
                      </div>
                    </div>
                    <div className=" col-md-8 border-gray mt-3"></div>
                    <div className="col-lg-6 col-md-12">
                      <div className="paragraph-holder">
                         <h5>AI-powered noise cancellation</h5>
                        <p>
                        Using automatic echo cancellation, automatic gain control, automatic noise suppression, and an AI-powered noise cancellation algorithm, Agora’s platform adapts to variant acoustic conditions to remove ambient and distracting noises, ensuring voices come through crystal clear.
                       </p>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                      <div className="image-holder">
                        <img src={tablet} className="img-fluid control" alt="..." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="pills-Event" role="tabpanel" aria-labelledby="pills-Event-tab" tabindex="0">
              <div className="row">
                  <div className="heading-holder text-center  my-3">
                    <h2>Stream audio to a worldwide audience</h2>
                  </div>
                  <div className="col-md-6">
                    <div className="paragraph-holder my-3">
                      <h3>Bring live event action and excitement to fans who can attend from anywhere. Whether it’s commentary for the World Cup or in-depth reporting of a major news event, with ultra-low latency, everyone experiences each moment at the same time, allowing them to interact with each other as if they were together in person.  </h3>
                    </div>
                    <div className="text-holder my-4">
                      <h3>THE AUDIOMEETS PRODUCTS THAT MAKE THIS POSSIBLE:</h3>
                      <div className="d-flex my-4">
                        <p>Voice Calling</p>
                        <div className="streaming ">
                          <p className="ps-3">Interactive Live Streaming</p>
                        </div>
                        <div className="streaming ">
                          <p className="ps-3">Signaling</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className='circle-img'>
                      <img src={circle} />
                      <div className="image-holder">
                        <img src={groupimg} className="img-fluid rightmeet" alt="..." />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="heading-holder  mt-5">
                    <p className="text-center"> FEATURES</p>
                    <h2>Every feature you need for interactive audio streaming</h2>
                  </div>
                  <div class="row mt-5">
                    <div className=" col-md-4">
                      <div className="engage_content">
                        <h1 className="">Outstanding Audio</h1>
                      </div>
                    </div>
                    <div className="col-md-8 border-gray mt-3"></div>
                    <div className="col-lg-6 col-md-12">
                      <div className="paragraph-holder">
                        <h5>Full Bandwidth Capture </h5>
                        <p>
                        Full Bandwidth Capture A 48kHz (sampling rate) full-sound bandwidth capture provides the most natural audio reproduction for podcasts, music rooms, KTV, and other uses where audio quality is of critical importance. Stream audio up to 192kbps to reproduce the original audio source in high fidelity.
                       </p>
                       <h5>Fast initial rendering and channel switching </h5>
                        <p>
                        Audiomeets’ sub-second initial audio rendering and channel-switching time creates a seamless experience for media listeners.
                       </p>
                       <h5>Adaptable audio bitrate </h5>
                        <p>
                        Select an audio profile with a bitrate of 18kbps to 192kbps to provide the appropriate quality for your content needs.
                       </p>
                       <h5>Smooth experience </h5>
                        <p>
                        Audiomeets’ sophisticated network algorithms minimize latency and packet loss for a smooth, stutter-free experience without interruptions.
                       </p>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                      <div className="image-holder">
                        <img src={podcast} className="img-fluid podcast" alt="..." />
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div className=" col-md-8 border-gray mt-3"></div>
                    <div className=" col-md-4">
                      <div className="engage_content">
                        <h1 className="">Customizable Options</h1>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                      <div className="image-holder">
                        <img src={laptop} className="img-fluid laptop" alt="..." />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="paragraph-holder">
                      <h5>Cross-channel co-hosts </h5>
                        <p>
                        Create a competition or co-host event with hosts from up to four channels in an audio stream, perfect for live streaming social gatherings jointly or hosting competitions between cooks, DJs, or performance artists in different virtual rooms.
                       </p>
                       <h5>Voice effects  </h5>
                        <p>
                        Make streams fun and engaging with a range of voice effects. From sound mixing to sound reverb, users can change the way their voices sound to match their moods, characters they’re playing, or just satisfy a whim.
                       </p>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div className=" col-md-4">
                      <div className="engage_content">
                        <h1 className="">Versatile Controls</h1>
                      </div>
                    </div>
                    <div className=" col-md-8 border-gray mt-3"></div>
                    <div className="col-lg-6 col-md-12">
                      <div className="paragraph-holder">
                         <h5>AI-powered noise cancellation</h5>
                        <p>
                        Using automatic echo cancellation, automatic gain control, automatic noise suppression, and an AI-powered noise cancellation algorithm, Agora’s platform adapts to variant acoustic conditions to remove ambient and distracting noises, ensuring voices come through crystal clear.
                       </p>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                      <div className="image-holder">
                        <img src={tablet} className="img-fluid control" alt="..." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default EduStream;
