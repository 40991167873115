import React from "react";
import "../Terms/Terms.css";

export default function Terms() {
  return (
    <div>
      <section className="Terms">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="background_color">
                <h1 className="heading1 pt-3 px-4 pb-4"> Terms & Conditions</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h4 className="heading2 py-4">
                Subscribers are requested to acknowledge and accept the
                following terms and conditions-
              </h4>
              <div className="list-item1">
                <ol>
                  <li>
                    {" "}
                    Online Payment for Member Subscriptions to Audio meet can
                    make through our website.
                  </li>
                  <li>
                    You are eligible to make a Contribution and Payment for
                    registration of your name to Audio meet, subject to these
                    Terms and Conditions.
                  </li>
                  <li>
                    {" "}
                    With payment for registration and for subscription, the
                    Audio meet is available for everyone.
                  </li>
                  <li>
                    If your Payment for the Member subscription transaction is
                    successful, you will receive an email acknowledgment of the
                    same from our finance team.
                  </li>
                  <li>
                    Contribution and Payment for Member subscription to the
                    Audio meet do not entitle you to any goods, services,
                    positions from Audio meet, or any other consideration.
                  </li>
                  <li>
                    Once a subscription transaction is completed, no refund can
                    be given under any circumstances.
                  </li>
                  <li>
                    We accept all Visa, Master, and Maestro cards, Indian Only.
                  </li>
                  <li>
                    Our secure payment gateway requests credit/debit card
                    information, but we do not collect it. You will not be asked
                    to provide your card details to the Audio meet.
                  </li>
                  <li>
                    Audio meet shall be under no liability whatsoever in respect
                    of any loss or damage arising directly or indirectly out of
                    the decline of authorization for any Transaction.
                  </li>
                  <li>
                    {" "}
                    We ask for the below-listed information, which will be held
                    securely, and will only use this information to (a) process
                    contributions and provide receipts (b) maintain accurate
                    records of accounts for auditing purposes (c) circulate
                    occasional updates as relevant - Full Name Address Date of
                    Birth Phone/Mobile Email Contribution Amount PAN Number/
                    Copy AADHAAR Number / Copy
                    <ul>
                      <li> Full Name </li>
                      <li>  Address</li>
                      <li> Date of Birth</li>
                      <li>  Phone/Mobile</li>
                      <li>  Email</li>
                      <li>  Contribution Amount</li>
                      <li>  PAN Number/ Copy</li>
                      <li> AADHAAR Number / Copy</li>
                    </ul>
                    <li>
                      Audio meet reserves the right to modify or replace any of
                      these terms and conditions at any time, and any change
                      shall be effective once posted on the Websites.
                    </li>
                    <li>
                      {" "}
                      Subscription and Payment for Members' subscriptions are
                      accepted from Indian Nationals as well as NRIs.
                    </li>
                    <li>
                      {" "}
                      You understand and agree that in addition to these Terms
                      and Conditions, the Subscribers shall also be bound by the
                      terms and conditions of the Bank Website and/or any
                      additional payment gateway/software service provider while
                      making a payment towards Audio meet on the Bank Website
                      and/or once he/she has been redirected away from the Audio
                      meet Website. Indian Nationality/NRI’s Subscribers also
                      understand and agrees that the personal or sensitive
                      personal information provided by the Indian Nationality
                      /NRI’s The terms of the third-party websites shall govern
                      the behavior of Star subscribers on such third-party
                      websites.
                    </li>
                    <li>
                      In case of any network failure and /or technical failure
                      in accessing the Audio meet Website and /or Bank Website
                      and /or unclear network, breakdown of machinery,
                      disruption of the network, and/or for any reasons
                      whatsoever, Audio meet reserves the right to at its sole
                      discretion to cease accepting subscription from the Indian
                      Nationality Subscribers (s), in any manner as it may deem
                      fit and necessary. In addition to the aforesaid, Audio
                      meet shall not be liable and responsible in any manner
                      whatsoever in the event of any malfunction/fault
                      /failure/disruption while making Payment for Member
                      subscription on the Bank Website and/or in the internet
                      /network services /system failure / due to technical
                      /technological restrictions and due to reasons
                      /events/incidents beyond the commercially reasonable
                      control of Star and the Subscribers shall hold harmless
                      the Audio meet and its authority or any other person in
                      relation to the Campaign with respect to the same. Any
                      dispute in connection to the above shall be settled
                      between the subscribers and the Bank Website directly,
                      without involving Audio meet in any manner whatsoever.
                    </li>
                    <li>
                      We accept Payments from Indian Nationals, Non-Residential
                      Indian Nationals,
                    </li>
                    <li>
                      {" "}
                      Funds are used in accordance with our goals as decided by
                      the trustees.
                    </li>
                    <li>
                      {" "}
                      We may change these terms, conditions, cancellation, and
                      return policies without any prior notice, we will update
                      the policies on our website.
                    </li>
                  </li>
                </ol>
                <div className="black_background">
                  <p className="paragraph py-4 text-center">
                    For any information or questions, please feel free to reach
                    us on:
                    <br />
                    Email: dksspune03@gmail.com / dksspune03@gmail.com
                  </p>
                </div>
                <h1 className="heading3 my-4">Cancellation & Refund Policy</h1>
                <h4 className="heading4 py-3">
                  Subscribers are requested to have acknowledged and accepted
                  the following Cancellation & Return Policy.
                </h4>
                <div className="list-item2">
                  <ol>
                    <li>
                      Once the subscription is made it cannot be canceled.
                    </li>
                    <li>
                      . Once the subscription is made it cannot be returned.
                    </li>
                    <li>
                      {" "}
                      Subscription and Payment for Member subscriptions cannot
                      be refunded.{" "}
                    </li>
                    <li>
                      {" "}
                      All rights related to cancellation and refund are reserved
                      with Audio meet.
                    </li>
                  </ol>
                </div>
                <h1 className="heading3 my-4">
                  Contribution Amount (Pricing) Policy
                </h1>
                <h4 className="heading4 py-3">
                  Subscribers are requested to have acknowledged and accepted
                  the Pricing Policy.
                </h4>
                <div className="list-item2">
                  <ol>
                    <li>
                      . You as a subscriber make a subscription to Audio Meets
                      on a voluntary basis.
                    </li>
                    <li>
                      . Subscription to the Audio Meets does not entitle you to
                      any goods, services, positions at / from the Audio Meets,
                      or any other consideration.
                    </li>
                    <li>
                      . Payment gateway charges TDR to Audio meet for online
                      transactions done.
                    </li>
                    <li>
                      Subscribers may have to pay these additional charges for
                      which Audio Meets is not liable to issue receipts but only
                      for the subscription amount.
                    </li>
                    <li>
                      {" "}
                      The maximum amount of online at-will contribution is
                      Rs.10,00,000 (Rupees Ten Lakh) if you want to give a will
                      Contribution of more than this then contact Audio Meets by{" "}
                    </li>
                  </ol>
                </div>
                <div className="row">
                  <div className="col-md-6 mx-auto">
                    <div className="email_background">
                      <p className="email py-3 px-3">
                        Email: dksspune03@gmail.com or by Phone: +91 7030750123
                      </p>
                    </div>
                  </div>
                </div>
                <p className="last_line mb-5">
                  Apart from this, your bank or payment gateway may also have a
                  limit on the maximum amount per transaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
