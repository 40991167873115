import React from 'react'
import '../bussinessbilled/bussinessbilled.css';
import indiaflag from '../imgs/india-flag.png';
import usaflag from '../imgs/usa-flag.png'
const BussinessBilled = () => {
    return (
        <>
            <div className='row billed'>
                <div className='col-md-6'>
                    <div className='d-flex'>
                        <div className='plan my-3'>
                            <h4>View plans for:</h4>
                        </div>
                        <div class="form-check my-3 mx-2">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label class="form-check-label" for="flexRadioDefault1">
                                Products
                            </label>
                        </div>
                        <div class="form-check my-3 mx-2">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                            <label class="form-check-label" for="flexRadioDefault2">
                                Industry
                            </label>
                        </div>
                    </div>
                    </div>
                    <div className='col-md-6'>
                    <div className='d-flex justify-content-end'>
                        <div class="form-check my-3 mx-2">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label class="form-check-label" for="flexRadioDefault1">
                                Billed Annually<br /><span>SAVE 20%</span>
                            </label>
                        </div>
                        <div class="form-check my-3 mx-2">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                            <label class="form-check-label" for="flexRadioDefault2">
                                Billed Monthly
                            </label>
                        </div>
                    </div>
                </div>
                <ul class="nav nav-pills mb-5 justify-content-center mt-3" id="pills-tab" role="tablist">
                    <li class="nav-item   me-3" role="presentation">
                        <button class="nav-link active" id="pills-Team-tab" data-bs-toggle="pill" data-bs-target="#pills-Team" type="button" role="tab" aria-controls="pills-Team" aria-selected="true">Audio Meets<br /> <span>Team Chat, Phone & More</span></button>
                    </li>
                    <li class="nav-item me-3" role="presentation">
                        <button class="nav-link" id="pills-Phone-tab" data-bs-toggle="pill" data-bs-target="#pills-Phone" type="button" role="tab" aria-controls="pills-Phone" aria-selected="false">Audio Meets <br /> Phone</button>
                    </li>
                    <li class="nav-item me-3" role="presentation">
                        <button class="nav-link" id="pills-Webinars-tab" data-bs-toggle="pill" data-bs-target="#pills-Webinars" type="button" role="tab" aria-controls="pills-Webinars" aria-selected="false">Audio Meets Events <br />and Webinars</button>
                    </li>
                    <li class="nav-item me-3" role="presentation">
                        <button class="nav-link" id="pills-Rooms-tab" data-bs-toggle="pill" data-bs-target="#pills-Rooms" type="button" role="tab" aria-controls="pills-Rooms" aria-selected="false">Audio Meets<br /> Rooms</button>
                    </li>
                    <li class="nav-item me-3" role="presentation">
                        <button class="nav-link" id="pills-Center-tab" data-bs-toggle="pill" data-bs-target="#pills-Center" type="button" role="tab" aria-controls="pills-Center" aria-selected="false">Audio Meets<br />Contact Center</button>
                    </li>
                    <li class="nav-item me-3" role="presentation">
                        <button class="nav-link" id="pills-Whiteboard-tab" data-bs-toggle="pill" data-bs-target="#pills-Whiteboard" type="button" role="tab" aria-controls="pills-Whiteboard" aria-selected="false">Audio Meets<br /> Whiteboard</button>
                    </li>
                </ul>
                <div className='d-flex justify-content-end'>
                    <div className='select-box'>
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Indian Rupee ₹</option>
                            <option value="1" >US Dollars $<img src={indiaflag} className='flag' /></option>
                            <option value="2">Indian Rupee ₹ <img src={usaflag} className='flag' /> </option>
                        </select>
                    </div>
                    <div className='select-box'>
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Call from India</option>
                            <option value="1">USA</option>
                            <option value="2">England</option>
                            <option value="3">Australia</option>
                            <option value="3">India</option>
                            <option value="3">Australia</option>
                            <option value="3">Australia</option>
                        </select>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BussinessBilled