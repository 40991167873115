import React from 'react'
import '../humanize-virtual/humanize.css';
import frame from "../imgs/frame.png";
import networking from "../imgs/networking.png";
import beats from "../imgs/beats.png";
const Humanize = () => {
  return (
    <>
     <section className="Humanize-virtual">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="Humanize_Heading py-3">Humanize Virtual Care</h1>
              <p className="build">
              Provide quality healthcare services anywhere, anytime
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-12">
              <div className="card mb-3">
                <img src={frame} className="frame_img img-fluid" alt="..." />
                <div className='card-body'>
                  <div className="card-title">
                    <h3 >Improve Patient Experience</h3>
                    <p >
                    Use video and real-time communication tools to create quality patient experiences. Connect medical professionals to colleagues and patients with live Video, Voice, and Chat. Facilitate virtual consultations, status reports, check-ins and enable specialists to monitor situations or procedures from remote locations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="card mb-3">
                <img src={networking} className="frame_img img-fluid" alt="..." />
                <div className='card-body'>
                  <div className="card-title">
                    <h3>Connect & Collaborate</h3>
                    <p>
                    Quickly integrate real-time voice, video, and messaging with low-code or no-code solutions. Scale your healthcare services from one-on-one consultations to large group training or coaching sessions. With coverage for over 200+ countries and regions, enable healthcare teams to stay connected with one another and patients without compromising reliability or quality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="card mb-3">
                <img src={beats} className="frame_img img-fluid" alt="..." />
                <div className='card-body'>
                  <div className="card-title">
                    <h3 >Stress-Free Compliance</h3>
                    <p >
                    Ensure that critical information is shared effectively and securely. Protect personal data & meet HIPAA compliance standards with Audiomeets' private network and communication solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Humanize