import React from "react";
import "../Feature/Feature.css";
// import Groups from "../imgs/Groups.png";
import Masks from "../imgs/Masks.png";
import logo from "../imgs/logo.png";

const Feature = () => {
  return (
    <>
      {/* </section> */}
      <section className="Feature">
        <div className="container">
          <div className="row">
            <h1>Features</h1>
          </div>
          <div className="row feature-banner">
            <div className="col-lg-4 col-md-12">
              <div className="image-holder">
                <img src={Masks} alt="..." className="image2 img-fluid" />
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="mt-5">
              <div className="d-flex">
                <div className="img-holder">
                  <img src={logo} alt="..." className="logo_img1 me-4" />
                </div>
                <p className="cloud">
                  Cloud VoIP Phone System
                </p>
              </div>
              <div className="d-flex">
                <div className="img-holder">
                  <img src={logo} alt="..." className="logo_img1 me-4" />
                </div>
                <p className="cloud">
                  Conversation with the host in Private
                </p>
              </div>
              <div className="d-flex">
                <div className="img-holder">
                  <img src={logo} alt="..." className="logo_img1 me-4" />
                </div>
                <p className="cloud">
                  Audio virtual meetings privately
                </p>
              </div>
              <div className="d-flex">
                <div className="img-holder">
                  <img src={logo} alt="..." className="logo_img1 me-4" />
                </div>
                <p className="cloud">
                  Audio Webinars
                </p>
              </div>
              <div className="d-flex">
                <div className="img-holder">
                  <img src={logo} alt="..." className="logo_img1 me-4" />
                </div>
                <p className="cloud">
                  Intelligent conversation system
                </p>
              </div>
              <div className="d-flex">
                <div className="img-holder">
                  <img src={logo} alt="..." className="logo_img1 me-4" />
                </div>
                <p className="cloud">
                  Chat Recording
                </p>
              </div>
            </div>
            </div>
            <div className="col-lg-4 col-md-12">
            <div className="mt-5">
              <div className="d-flex">
                <div className="img-holder">
                  <img src={logo} alt="..." className="logo_img1 me-4" />
                </div>
                <p className="cloud">
                  Private virtual meeting
                </p>
              </div>
              <div className="d-flex">
                <div className="img-holder">
                  <img src={logo} alt="..." className="logo_img1 me-4" />
                </div>
                <p className="cloud">
                  Unlimited user connection
                </p>
              </div>
              <div className="d-flex">
                <div className="img-holder">
                  <img src={logo} alt="..." className="logo_img1 me-4" />
                </div>
                <p className="cloud">
                  Safe and reliable
                </p>
              </div>
              <div className="d-flex">
                <div className="img-holder">
                  <img src={logo} alt="..." className="logo_img1 me-4" />
                </div>
                <p className="cloud">
                  Secure
                </p>
              </div>
              <div className="d-flex">
                <div className="img-holder">
                  <img src={logo} alt="..." className="logo_img1 me-4" />
                </div>
                <p className="cloud">
                  Scalable
                </p>
              </div>
             </div>
          </div>
     
          </div>
        </div>
      </section>
      <section className="empower_heading">
        <div className="container">
          <div className="row">
            <div className="Heading">
              <h1 className="empowering my-4">Empowering different industries and organizations</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Feature;
