import React from 'react'
import RetailsBanner from './RetailsBanner/RetailsBanner'
import EngageSocial from './engage-social-shoppers/EngageSocial'
import LiveShopping from './live-shopping/LiveShopping'
import EcommerceExperience from './ecommerce-experience/EcommerceExperience'
import DelightAudience from './delight-audience/DelightAudience'

const RetailsPage = () => {
  return (
    <>
    <RetailsBanner/>
    <EngageSocial/>
    <LiveShopping/>
    <EcommerceExperience/>
    <DelightAudience/>
    </>
  )
}

export default RetailsPage
