import React from "react";
import "./EngageStudent.css";
import leftmeet from "../imgs/leftmeet.png";
import rightmeet from "../imgs/rightmeet.png";

const EngageStudent = () => {
  return (
    <>
      <section className="EngageStudent">
        <div className="container">
          <div className="row">
            <h1 className="Engage_Heading my-3">
              Engage Students. Support Teachers.
            </h1>
            <p className="engage_p">
              Audio meets provides everything you need to build a custom,
              branded virtual classroom at any scale.
            </p>
          </div>
          {/* 1st row */}

          <div class="row mt-5">
            <div className="col-lg-3 col-md-4">
              <div className="engage_content">
                <h1 className="">Online Tutoring</h1>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 border-gray mt-3"></div>
            <div className="col-lg-6 col-md-12">
              <div className="paragraph-holder">
                <p>
                  Customize virtual tutoring spaces with high quality
                  video/audio calls, real-time messaging, & engagement tools
                  including Breakout Rooms and an Interactive Whiteboard.
                </p>
                <p >
                  See how Noon Academy uses Agora to power their engaging,
                  gamification-based learning environment.
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div className="image-holder">
                <img src={leftmeet} className="img-fluid rightmeet" alt="..." />
              </div>
            </div>
          </div>
          <div class="row mt-5">
          <div className="col-lg-6 col-md-4 border-gray mt-3"></div>
            <div className="col-lg-6 col-md-8">
              <div className="engage_content">
                <h1 className="">Language Learning</h1>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div className="image-holder">
                <img src={leftmeet} className="img-fluid rightmeet" alt="..." />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="paragraph-holder">
                <p>
                Transform language training by building an immersive environment with social activities and video/audio chat rooms. Read how Audio meets helped Preply improve their lesson success rate by up to 18%.
                </p>
                <p >
                Read how Audio meets helped Preply improve their lesson success rate by up to 18%.
                </p>
              </div>
            </div>
            </div>
            <div class="row mt-5">
            <div className="col-lg-3 col-md-4">
              <div className="engage_content">
                <h1 className="">Enterprise Training</h1>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 border-gray mt-3"></div>
            <div className="col-lg-6 col-md-12">
              <div className="paragraph-holder">
             
                <p >
                Supercharge student engagement and course completion rate with live coaching sessions and interactive learning activities in real time.
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div className="image-holder">
                <img src={leftmeet} className="img-fluid rightmeet" alt="..." />
              </div>
            </div>
          </div>
          <div class="row mt-5">
          <div className="col-lg-6 col-md-4 border-gray mt-3"></div>
            <div className="col-lg-6 col-md-8">
              <div className="engage_content">
                <h1 className="">Online Proctoring</h1>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div className="image-holder">
                <img src={leftmeet} className="img-fluid rightmeet" alt="..." />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="paragraph-holder">
              
                <p >
                Empower live proctoring with single/dual camera settings and cloud or on-premise recording.
                </p>
              </div>
            </div>
            </div>
        </div>
      </section>
    </>
  );
};
export default EngageStudent;
