import React from "react";
import "../privacy-policy-folder/Privacy.css";

export default function PrivacyPolicy() {
  return (
    <>
      <section className="Privacy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="background_color">
                <h1 className="heading1 pt-3 px-4 pb-4"> Privacy Policy</h1>
              </div>
            </div>
          </div>
          {/* <div className="row ">
            <h3 className="Privacy_Heading my-3"> Our Privacy Policy Explains</h3>
            <div className="col-lg-4 col-md-12">
              <div className="bg-gray">
                <p className="px-3 py-2">
                  What information do we collect and why do we collect it?
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="bg-gray ">
                <p className="px-3 py-2">
                  How we use that information?
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="bg-gray">
                <p className="px-3 py-2">
                  Some more Privacy Policy details.
                </p>
              </div>
            </div> */}


            {/* Heading */}
            <div className="row">
              <div className="col-md-12">
                {/* <h1 className="Privacy_Heading2 mt-5">
                  What information do we collect and why do we collect it?
                </h1>
                <p className="p1 pt-2">
                  When you make a subscription to Audio meet, as part of the
                  online subscription process, we collect the personal
                  information you give us such as
                </p>
                <ul className="list">
                  <li>Your Name </li>
                  <li>Phone/Mobile Number </li>
                  <li>Email Address</li>
                  <li>AADHAAR Details</li>
                  <li>Postal Address</li>
                </ul>
                <div className="gray_background">
                  <h1 className="Privacy_Heading2 mx-5 pt-5">How we use this information</h1>
                  <p className="p2 mx-5 pt-3">We use some third-party services</p>
                  <div className="list_item  mx-5 mt-5">
                    <ol>
                      <li>In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow
                        them to perform the services they provide to us.</li>
                      <li>However, certain third-party service providers, such as banks, payment gateways, and other payment transaction processors, have
                        their own privacy policies with respect to the information we are required to provide to them for your purchase-related transactions.</li>
                      <li> For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal
                        information will be handled by these providers.</li>
                      <li>  Once you leave our website or are redirected to a third-party website or application, you are no longer governed by this Privacy
                        Policy or our website’s Terms of Service. </li>
                      <li> When you click on links on our website, they may direct you away from our site. We are not responsible for the privacy practices of
                        other sites and encourage you to read their privacy statements.</li>
                    </ol>
                  </div>
                </div> */}
                {/* <h1 className="Privacy_Heading2 mt-5">Some more Privacy Policy details</h1>

                <div className="list1 mt-4 mb-5">
               
                  <p> <span>1.PAYMENT :</span> We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. The data is
                    encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your
                    purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is
                    complete, your purchase transaction information is not saved. Our payment gateway adheres to the standards set by
                    PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard,
                    American Express, and Discover. PCI-DSS requirements help ensure the secure handling of credit card information by
                    our store and its service providers. For more insight, you may also want to read the terms and conditions of Razorpay at
                    https://razorpay.com
                  </p>
                  <p><span>2.SECURITY:</span>  To protect your personal information, we take reasonable precautions and follow industry best practices to make sure
                    it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed.</p>
                  <p> <span>3.COOKIES:</span>   We use cookies to maintain the session of your user. </p>
                  <p> <span>4.CHANGES TO THIS PRIVACY POLICY: </span> We reserve the right to modify this privacy policy at any time, so please review it frequently.
                    Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes
                    to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect,
                    how we use it, and under what circumstances, if any, we use and/or disclose it. If our store is acquired or merged with
                    another company, your information may be transferred to the new owners so that we may continue to sell products to
                    you.</p>
                  <p><span> 5.QUESTIONS AND CONTACT INFORMATION: </span>If you would like to: access, correct, amend or delete any personal information we have
                    about you, register a complaint, or simply want more information contact our authorized dignitary at
                    Email: dksspune03@gmail.com or by Phone: +91 7030750123or visit/mail to our postal address given on contact page.</p>

                </div> */}
                <h1 className="Privacy_Heading2 mt-5">Comments</h1>

                <div className="list1 mt-4 mb-4">

                  <p>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.  </p>
                  <p>An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it.
                    The Gravatar service privacy policy is available here: https://automattic.com/privacy/.
                    After approval of your comment, your profile picture is visible to the public in the context of your comment.</p>

                  <h1 className="Privacy_Heading2 mt-4">Media</h1>

                  <p> If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website. </p>

                  <h1 className="Privacy_Heading2 mt-4">Cookies</h1>
                  <p> If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies.
                    These are for your convenience so that you do not have to fill in your details again when you leave another comment.
                    These cookies will last for one year.</p>
                  <p>If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</p>

                  <h1 className="Privacy_Heading2 mt-4">When you log in, we will also set up several</h1>
                  <p>cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year.
                    If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</p>
                  <p>If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.</p>

                  <h1 className="Privacy_Heading2 mt-4">Embedded content from other websites</h1>
                  <p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>
                  <p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content,
                    including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>

                  <h1 className="Privacy_Heading2 mt-4">Who we share your data with</h1>
                  <p>If you request a password reset, your IP address will be included in the reset email.</p>

                  <h1 className="Privacy_Heading2 mt-4">How long we retain your data</h1>
                  <p>If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.</p>
                  <p>For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit,
                    or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.</p>

                  <h1 className="Privacy_Heading2 mt-4">What rights you have over your data</h1>
                  <p>If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you,
                    including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>

                  <h1 className="Privacy_Heading2 mt-4">Where we send your data</h1>
                  <p>Visitor comments may be checked through an automated spam detection service.</p>
                </div>


              </div>
            </div>
        
        </div>
      </section>
    </>
  );
}
