import React from "react";
import "../Usage/Usage.css";
import man from "../imgs/man.png";
import arrowicon from '../imgs/downarrow.png';
const Usage = () => {
  return (
    <>
      <section className="usage">
        <div className="container">
          <div className="row ">
            <div class="col-md-12 text-center">
              <h2 className="Usage_Heading">Usage</h2>
            </div>
            {/* 1 row */}
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-6">
                <div className="card mb-3">
                  <img src={man} className="industries_img img-fluid" alt="..." />
                  <div className="card-title">
                    <h3 className="manage mt-2">
                      Audio meet is easy to set up, use, and manage
                    </h3>
                  </div>
                   <div class="collapse" id="collapseExample1">
                      <div class="card card-body">
                     <p> Audio meet provides straight forward purchasing & deployment. It’s one click away to start or join any meeting, and Audio meet provides easy collaboration between the host and the person. In short, you don’t need a complete team to roll out and manage Audio meet.</p>
                      </div>
                    </div>
                    <button className="btn btn-detalis" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample1" aria-expanded="false" aria-controls="collapseExample"> <img src={arrowicon} className="arrow" /></button>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card mb-3">
                  <img src={man} className="industries_img img-fluid" alt="..." />
                  <div className="card-title">
                    <h3 className="manage mt-2">
                    Communicate at ease with your team
                    </h3>
                  </div>
                   <div class="collapse" id="collapseExample2">
                      <div class="card card-body">
                    <p> Audio meet provides straight forward purchasing & deployment. It’s one click away to start or join any meeting, and Audio meet provides easy collaboration between the host and the person. In short, you don’t need a complete team to roll out and manage Audio meet.</p>
                      </div>
                    </div>
                    <button className="btn btn-detalis" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample"> <img src={arrowicon} className="arrow" /></button>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card mb-3">
                  <img src={man} className="industries_img img-fluid" alt="..." />
                  <div className="card-title">
                    <h3 className="manage mt-2">
                    Use a single platform for audio meetings, calls, audio webinars, etc
                    </h3>
                  </div>
                   <div class="collapse" id="collapseExample3">
                      <div class="card card-body">
                    <p> Audio meet provides straight forward purchasing & deployment. It’s one click away to start or join any meeting, and Audio meet provides easy collaboration between the host and the person. In short, you don’t need a complete team to roll out and manage Audio meet.</p>
                      </div>
                    </div>
                    <button className="btn btn-detalis" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample"> <img src={arrowicon} className="arrow" /></button>
                </div>
              </div>
            </div>
            {/* 2ND row */}

             <div className="row justify-content-center mt-4">
             <div className="col-lg-3 col-md-6">
                <div className="card mb-3">
                  <img src={man} className="industries_img img-fluid" alt="..." />
                  <div className="card-title">
                    <h3 className="manage mt-2">
                    Use a single platform for audio meetings, calls, audio webinars, etc
                    </h3>
                  </div>
                   <div class="collapse" id="collapseExample4">
                      <div class="card card-body">
                    <p> Audio meet provides straight forward purchasing & deployment. It’s one click away to start or join any meeting, and Audio meet provides easy collaboration between the host and the person. In short, you don’t need a complete team to roll out and manage Audio meet.</p>
                      </div>
                    </div>
                    <button className="btn btn-detalis" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample4" aria-expanded="false" aria-controls="collapseExample"> <img src={arrowicon} className="arrow" /></button>
                </div>
              </div>
              
              <div className="col-lg-3 col-md-6">
                <div className="card mb-3">
                  <img src={man} className="industries_img img-fluid" alt="..." />
                  <div className="card-title">
                    <h3 className="manage mt-2">
                    Use a single platform for audio meetings, calls, audio webinars, etc
                    </h3>
                  </div>
                   <div class="collapse" id="collapseExample5">
                      <div class="card card-body">
                    <p> Audio meet provides straight forward purchasing & deployment. It’s one click away to start or join any meeting, and Audio meet provides easy collaboration between the host and the person. In short, you don’t need a complete team to roll out and manage Audio meet.</p>
                      </div>
                    </div>
                    <button className="btn btn-detalis" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample5" aria-expanded="false" aria-controls="collapseExample"> <img src={arrowicon} className="arrow" /></button>
                </div>
              </div>
              </div>
           
          </div>
        </div>
      </section>
    </>
  );
};
export default Usage;
