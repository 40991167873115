import React from 'react'
 import '../HealthSolutionBanner/HealthSolutionBanner.css'
 import img1 from "../imgs/Mobile.png";
import img2 from "../imgs/microscope.png";
const HealthSolutionBanner = () => {
  return (
    <>
    <section className="health-banner">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
              <div className="banner-title">
                <p className="education-solution">HEALTHTECH SOLUTIONS </p>
                <h3 className="customize">
                Provide Remote Healthcare in Real Time
                </h3>
              </div>
              <div className="border-green mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-2"></div>
              <div className="banner-caption-holder">
                <p className="real-time">
                Enable remote consultation, care, and coaching experiences with flexible video, voice, and chat solutions.
                </p>
              </div>
              <div className="banner-button">
                <button type="button" className="btn">
                  Talk to an Expert Today
                </button>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5">
              <div className="banner-img-holder">
                <img src={img1} alt="..." className="img-fluid img1" />
                <img src={img2} alt="..." className="img-fluid img2" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HealthSolutionBanner