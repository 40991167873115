import React from 'react'
import '../ecommerce-experience/ecommerceexperience.css'
const EcommerceExperience = () => {
  return (
    <>
    <section className="Blocks-ecommerce">
        <div className="container">
          <div class="row">
            <div className='heading-holder my-3'>
                <h1>Create live shoppable eCommerce experiences with ease</h1>
            </div>
            <div class="col-lg-3 col-md-12">
              <div className="grey-color">
                <div className="headimg-holder px-3 py-3">
                  <h2>Live Streaming</h2>
                </div>
              </div>
              <div className="text-holder mx-2 my-3">
                <p>Ultra-low latency</p>
                <p>Real-time messaging</p>
                <p>Live streaming</p>
                <p>Screen sharing</p>
                <p>Recording and playback</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-12">
              <div className="grey-color">
                <div className="headimg-holder px-3 py-3">
                  <h2>Real-Time Signaling</h2>
                </div>
              </div>
              <div className="text-holder mx-2 my-3">
                <p>Real-time synced data</p>
                <p>Reward system</p>
                <p>Class roster</p>
                <p>Hand raising</p>
                <p>User permission control</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-12">
              <div className="grey-color">
                <div className="headimg-holder px-3 py-3">
                  <h2>Audience Engagement</h2>
                </div>
              </div>
              <div className="text-holder mx-2 my-3">
                <p>Co-live streaming</p>
                <p>Annotation tools</p>
                <p>View and authoring control</p>
                <p>Courseware resource center</p>
                <p>Interactive media and animation</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-12">
              <div className="grey-color">
                <div className="headimg-holder px-3 py-3">
                  <h2>Content Moderation</h2>
                </div>
              </div>
              <div className="text-holder mx-2 my-3">
                <p>Real-time transcription</p>
                <p>Automated alert notifications</p>
                <p>AI-powered data insights</p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="buttons">
              <div className="d-flex justify-content-center">
                <div className="button1">
                <button className="btn btn-green me-2">Talk to an Expert</button>
                </div>
                <div className="button2">
                <button className="btn btn-dark ms-2">Schedule Your Demo</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EcommerceExperience