import React from "react";
import "./SuccessBanner.css";


const SuccessBanner = () => {
  return (
    <>
      <section className="SuccessBanner">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
           <div className="heading-holder my-5 ">
             <h2>SUCCESS STORIES</h2>
           </div>
           <div className="text-holder my-5">
             <h3>Trusted by top apps and developers around the globe</h3>
           </div>
           <div className="paragrafh-holder my-5">
             <p>Audio Meets provides customers and partners with high quality and reliable voice, video & messaging APIs.</p>
           </div>
           </div>
         </div>
        </div>
      </section>
    </>
  );
};
export default SuccessBanner;
