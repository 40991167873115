import React from "react";
import "../Customization/Customization.css";
import emailicon from "../imgs/email.png";
import calendericon from "../imgs/calendar.png";
import boardicon from "../imgs/screen-share.png";
import playlicon from "../imgs/playlist-add.png";
import videoicon from "../imgs/file-video.png";
const Hosting = () => {
  return (
    <>
      <section className="Customization">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-5 col-md-12 black-set-color">
              <p>Customizations Available</p>
            </div>
            <div className="col-lg-7 col-md-12 blur-img">
              <div className="row mt-4">
                <div className="col-lg-6 col-md-6 ">
                  <div className="text-center mb-3">
                    <button className="btn btn-social "><img src={emailicon} className="me-3" />E-mail</button>
                  </div>
                </div>
                <div className="col-lg-6  col-md-6 ">
                  <div className="text-center mb-3">
                    <button className="btn btn-social "><img src={calendericon} className="me-3" />Calendar</button>
                  </div>
                </div>
                <div className="col-lg-6  col-md-6 ">
                  <div className="text-center mb-3 ">
                    <button className="btn btn-social "><img src={boardicon} className="me-3" />Board Sharing</button>
                  </div>
                </div>
                <div className="col-lg-6  col-md-6 ">
                  <div className="text-center mb-3">
                    <button className="btn btn-social "><img src={playlicon} className="me-3" />Integration</button>
                  </div>
                </div>
                <div className="col-lg-6  col-md-6 ">
                  <div className="text-center mb-3">
                    <button className="btn btn-video "><img src={videoicon} className="me-3" />Video Conferencing</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Hosting;
