import React from "react";
import "../Hosting/Hosting.css";
import boximg from "../imgs/boximg.png";
const Hosting = () => {
  return (
    <>
      <section className="Hosting">
        <div className="container">
          <div className="row">
            <div className="col-md-5 black_box">
              <h1 className="Expert-Heading mt-5 ps-3 mb-3">
                Become an Expert at hosting virtual events with audio meet
              </h1>
            </div>
            <div className="col-md-7 box_img">
            <div className="tt">
            {/* <img src={boximg} className="box_img img-fluid" alt="..." /> */}
             <p className="box-text  ps-3 mb-3">
                Understand the ins and outs of how a virtual event platform can
                help you host more engaging external and internal events by
                enhancing your hosting skills with the resources to help you get
                started, you could rely on audio-meet for an engaging,
                high-quality experience for all attendees.
              </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Hosting;
