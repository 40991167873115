import React from 'react'
import '../engage-social-shoppers/engagesocial.css';
const EngageSocial = () => {
  return (
    <>
       <section className="Engage-Social">
                <div className="container">
                    <div className="row">
                        <h1 className="boundless">Engage Social Shoppers. Stream Live Auctions. Upsell and Cross-Sell.</h1>
                        <div className="text-holder">
                            <p >
                            Bring the marketplace to life with human interaction. Decrease shopping cart abandonment and increase cross-sell by<br/> encouraging customers to socialize with each other and influencers to purchase an entire look, find seasonal necessities, get expert advice, and more.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}

export default EngageSocial