import React from 'react'
import HealthSolutionBanner from './HealthSolutionBanner/HealthSolutionBanner'
import CareGap from './care-gaps-folder/CareGap'
import Humanize from './humanize-virtual/Humanize'
import TreatPatients from './treat-patients/TreatPatients'

const HealthSolution = () => {
  return (
    <>
    <HealthSolutionBanner/>
    <CareGap/>
    <Humanize/>
    <TreatPatients/>
    </>
  )
}

export default HealthSolution