import React from "react";
import "./PhoneBanner.css";
import greenclick from "../imgs/greenclick.png";

const PhoneBanner = () => {
  return (
    <>
      <section className="PhoneBanner">
        <div className="container">
          <div className="row phone_banner_list">
          {/* <div className=""> */}
            <div className="col-lg-7 col-md-12">
            <h1 className="flexible my-4">Flexible Business VoIP Phone Service</h1>
          <div className="d-flex">
            <div className="img-green-icon">
              <img src={greenclick} className="me-3"/>
            </div>
            <div className="text-holder">
              <p>Empower your end users to make group calls in private with the host with ease and familiarity.</p>
            </div>
          </div>
          <div className="d-flex">
            <div className="img-green-icon">
              <img src={greenclick} className="me-3"/>
            </div>
            <div className="text-holder">
              <p>Empower your end users to make group calls in private with the host with ease and familiarity. Learn how our audio meet cloud phone systems perform better than the other phone solutions that fall short in providing workforce support. Our VoIP phone system’s user-friendly interfaces enable easy group management and end-user management.</p>
            </div>
          </div>
          <div className="d-flex mb-4">
            <div className="img-green-icon">
              <img src={greenclick} className="me-3"/>
            </div>
            <div className="text-holder">
              <p>Empower your end users to make group calls in private with the host with ease and familiarity. Learn how our audio meet cloud phone systems perform better than the other phone solutions that fall short in providing workforce support. Our VoIP phone system’s user-friendly interfaces enable easy group management and end-user management.</p>
            </div>
          </div>
              </div>
            {/* </div> */}
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <h1 className="innovation mb-3">Innovative for Your Evolving Needs</h1>
              <ul className="list">
                      <li> The audio meet has a track record of rapid innovation. Our hard phone and softphone systems capitalize on the established global infrastructure and audio quality of Audio meet. </li>
                      <li>  With several features, and more adde   d every month, you can count on, the Audio meet to meet all your business requirements.</li>
                      <li> The Audio meet tightly integrates with top business applications, contact centers, partners, and hardware providers to ensure communications in context.</li>
                      <li>Leverage audio meets a robust set of APIs to create customized business solutions that let you make and receive phone calls right within your workflow. </li>
                    
                    </ul>
            </div>
          </div>
    
        </div>
      </section>
    </>
  );
};
export default PhoneBanner;
