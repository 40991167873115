import React from "react";
import "./BuiltEdu.css";
import frame from "../imgs/frame.png";
import networking from "../imgs/networking.png";
import beats from "../imgs/beats.png";
const BuiltEdu = () => {
  return (
    <>
      <section className="BuiltEdu">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="Humanize_Heading py-3">Built for Education</h1>
              <p className="build">
                Build and scale your virtual classroom with what matters most to
                your teachers and students
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-12">
              <div className="card mb-3">
                <img src={frame} className="frame_img img-fluid" alt="..." />
                <div className='card-body'>
                  <div className="card-title">
                    <h3 >Any Class Size</h3>
                    <p >
                      Quickly and easily scale your class from 1-on-1 up to 1
                      million students. Whether you’re building on mobile apps or
                      desktop browsers, Agora’s intelligent global network ensures
                      you’ll never need to worry about lags or dropped calls no
                      matter where you are in the world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="card mb-3">
                <img src={networking} className="frame_img img-fluid" alt="..." />
                <div className='card-body'>
                  <div className="card-title">
                    <h3>Meaningful Engagement</h3>
                    <p>
                      Increase classroom efficiency with a suite of collaborative
                      and interactive tools built with teachers and students in
                      mind. Pick and choose the tools that work for your needs so
                      your teachers and students can focus on what matters –
                      teaching and learning.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="card mb-3">
                <img src={beats} className="frame_img img-fluid" alt="..." />
                <div className='card-body'>
                  <div className="card-title">
                    <h3 >Full Customization</h3>
                    <p >
                      Create a tailored online learning application that works for
                      your customers’ demands without sacrificing your brand. With
                      Agora, you have full control of the branding and
                      functionality of your app, while keeping your users’
                      sensitive data safely in your hands.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default BuiltEdu;
