import React from "react";
import "./Banner.css";
import img1 from "../imgs/Bannerimg1.png";
import img2 from "../imgs/Masks.png";
const Banner = () => {
  return (
    <>
      <section className="home-banner">
        <div className="container">
          <div className="row banner-row">
            <div className="col-md-7">
              <div className="my-5">
                <h1 className="heading">
                  Streamline Communication
                  <br />
                  and Team Collaboration
                  <br />
                  in One Place.
                </h1>
              </div>
              <div className="col-md-9">
                <div className="text-holder">
                  <p>
                    An effective system to Connect easily, privately, & securely
                    to coordinate efficiently, as a team. We offer everything
                    that you need for better & more meaningful team
                    communication, establish connections, & facilitate better
                    team operation for smooth interaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="banner-img-holder mx-auto">
                <img src={img1} alt="..." className="img-fluid img1" />
                <img src={img2} alt="..." className="img-fluid img2" />
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
};

export default Banner;
