import React from 'react'
import '../SuccessStoriesCards/SuccessStoriesCards.css'
import virdatta from '../imgs/Vidarbha.png'
import snopix from '../imgs/SINOPEX.png'
import green from '../imgs/Star.png'
const SuccessStoriesCards = () => {
  return (
    <>
      <section className='feature-story'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-3 '>
              <div className='bordergreen'></div>
            </div>
            <div className='col-lg-6'>
              <div class="heading-holder text-center">
                <h2 className='mx-3'> FEATURED  </h2>
                <h2 className='mx-3'>  SUCCESS  </h2>
                <h2 className='mx-3'> STORIES</h2>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='bordergreen'></div>
            </div>
          </div>
          <div className='row mt-5 mb-5'>
            <div className='col-lg-4 col-md-6'>
              <div class="card  mb-3">
                <div class="card-header text-center"><img src={virdatta} className='virdatta' /></div>
                <div class="card-body">
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div class="card  mb-3">
                <div class="card-header text-center"><img src={snopix} className='snopix' /></div>
                <div class="card-body">
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div class="card  mb-3">
                <div class="card-header text-center"><img src={green} className='grren' /></div>
                <div class="card-body">
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SuccessStoriesCards