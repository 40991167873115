import React from "react";
import "./CompareAll.css";
import rightimg from '../imgs/tick.png';
import arrowimg from '../imgs/arrow.png';
const CompareAll = () => {
  return (
    <>
      <div className="row comapare-all">
        <div className="button text-center">
          <button className="btn btn-campare">Compare All Plans</button>
        </div>
        <div className="headimg-holder text-center my-5">
          <h2>Optional Audio Meets Plan Add-ons</h2>
          <h3>*You must have at least one Licensed user to purchase these Add-on plans.</h3>
        </div>

        <div className="row">
          <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 ">
            <div class="card  mb-3">
              <div class="card-header text-center">Audiomeets Whiteboard</div>
              <div class="card-body">
                <h5 class="card-title">Starting at ₹1,824 per year</h5>
                <div className="d-flex mt-4">
                  <div className="image-right">
                    <img src={rightimg} className="right" />
                  </div>
                  <div className="unlimited">
                    <p >Unlimited concurrently editable boards</p>
                  </div>
                 </div>
              </div>
              <div class="card-footer">
                <div className="row">
                  <div className="col-6">
                    <h6>Discover Whiteboard</h6>
                  </div>
                  <div className="col-6">
                    <div className="button-buy">
                      <button className="btn btn-buy">Buy Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 ">
            <div class="card  mb-3">
              <div class="card-header text-center">Large Meetings</div>
              <div class="card-body">
                <h5 class="card-title">Starting at ₹52,800 per year</h5>
                <div className="d-flex mt-4">
                  <div className="image-right">
                    <img src={rightimg} className="right" />
                  </div>
                  <div className="unlimited">
                    <p >Increase participants capacity for your meetings plan</p>
                  </div>
                 </div>
                 <div className="d-flex mt-2">
                  <div className="image-right">
                    <img src={rightimg} className="right" />
                  </div>
                  <div className="unlimited">
                    <p >Include up to 500 or 1,000 interactive participants in a meeting</p>
                  </div>
                 </div>
              </div>
              <div class="card-footer">
                <div className="row">
                  <div className="col-6">
                    <div className="button-more">
                      <button className="btn btn-more">More Features<img src={arrowimg} className="ms-2"/></button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="button-buy">
                      <button className="btn btn-buy">Buy Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 ">
            <div class="card  mb-3">
              <div class="card-header text-center">Cloud Storage</div>
              <div class="card-body">
                <h5 class="card-title">Starting at ₹8,796 per year</h5>
                <div className="d-flex mt-4">
                  <div className="image-right">
                    <img src={rightimg} className="right me-2" />
                  </div>
                  <div className="unlimited">
                    <p >Store, stream and download your video recordings from the Audiomeets Cloud MP4 or M4A file formats available</p>
                  </div>
                 </div>
                 <div className="d-flex mt-2">
                  <div className="image-right">
                    <img src={rightimg} className="right me-2" />
                  </div>
                  <div className="unlimited">
                    <p >MP4 or M4A file formats available</p>
                  </div>
                 </div>
              </div>
              <div class="card-footer">
                <div className="row">
                  <div className="col-6">
                  <div className="button-more">
                      <button className="btn btn-more">More Features<img src={arrowimg} className="ms-2"/></button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="button-buy">
                      <button className="btn btn-buy">Buy Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 ">
            <div class="card  mb-3">
              <div class="card-header text-center">Audio Conferencing</div>
              <div class="card-body">
                <h5 class="card-title">Starting at ₹120,000 per year</h5>
                <div className="d-flex mt-4">
                  <div className="image-right">
                    <img src={rightimg} className="right me-2" />
                  </div>
                  <div className="unlimited">
                    <p >All paid plans come with local toll numbers, but Audio Plan allows you to add Call Out, global Toll-free and local dial-in for premium countries</p>
                  </div>
                 </div>
              </div>
              <div class="card-footer">
                <div className="row">
                  <div className="col-6">
                  <div className="button-more">
                      <button className="btn btn-more">More Features<img src={arrowimg} className="ms-2"/></button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="button-buy">
                      <button className="btn btn-buy">Buy Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 ">
            <div class="card  mb-3">
              <div class="card-header text-center">Premier Support</div>
              <div class="card-body">
                <h5 class="card-title">Starting at ₹1,824 per year</h5>
                <div className="d-flex mt-4">
                  <div className="image-right">
                    <img src={rightimg} className="right me-2" />
                  </div>
                  <div className="unlimited">
                    <p >Minimize downtime and get support issues resolved quickly with priority response <br/>Connect directly with support engineers to diagnose problems via phone, chat, or email</p>
                  </div>
                 </div>
              </div>
              <div class="card-footer">
                <div className="row">
                  <div className="col-6">
                  <div className="button-more">
                      <button className="btn btn-more">More Features<img src={arrowimg} className="ms-2"/></button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="button-buy">
                      <button className="btn btn-buy">Buy Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6">
            <div class="card  mb-3">
              <div class="card-header text-center">Conference Room Connector</div>
              <div class="card-body">
                <h5 class="card-title">Starting at ₹1,824 per year</h5>
                <div className="d-flex mt-4">
                  <div className="image-right me-2">
                    <img src={rightimg} className="right" />
                  </div>
                  <div className="unlimited">
                    <p >Connect your H.323/SIP systems with desktop, tablet, mobile devices, Zoom Rooms and other H.323/SIP endpoints</p>
                  </div>
                 </div>
              </div>
              <div class="card-footer">
                <div className="row">
                  <div className="col-6">
                  <div className="button-more">
                      <button className="btn btn-more">More Features<img src={arrowimg} className="ms-2"/></button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="button-buy">
                      <button className="btn btn-buy">Buy Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6">
            <div class="card  mb-3">
              <div class="card-header text-center">Audiomeets Mesh</div>
              <div class="card-body">
                <h5 class="card-title">Contact us for pricing details and more</h5>
                <div className="d-flex mt-4">
                  <div className="image-right">
                    <img src={rightimg} className="right me-2" />
                  </div>
                  <div className="unlimited">
                    <p >Conduct high-quality Webinars & Events in any location regardless of available bandwidth and attendee numbers</p>
                  </div>
                 </div>
              </div>
              <div class="card-footer">
                <div className="row">
                  <div className="col-6">
                  <div className="button-more">
                      <button className="btn btn-more">More Features<img src={arrowimg} className="ms-2"/></button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="button-buy">
                      <button className="btn btn-buy">Buy Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompareAll;
