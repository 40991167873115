import React from 'react'
import './product.css';
import virtualimg from './image/virtual-meetings.png';
import teamimg from './image/Team-Chat.png';
import voiimg from './image/VoIP-Phone-System.png';
import onlineimg from './image/Online-Whiteboard.png';
import conversionalimg from './image/Conversation-Intelligence.png';
import emailimg from './image/Email-and-Calendar.png';
import virtualworkimg from './image/Virtual-Working-Spaces.png';
import roomimg from './image/Conference-Room-Systems.png';
import connectimg from './image/Connected-Conference-Rooms.png';
import spaceimg from './image/Workspaces.png';
import eventimg from './image/Event-Platform.png';
import singleimg from './image/Single-Session-Events.png';
import webinarmg from './image/Online-Webinars.png';
import communityimg from './image/Community-Events.png';
import centerimg from './image/Cloud-Contact-Center.png';
import agentimg from './image/Virtual-Agent.png';
import { Link } from "react-router-dom";
const Procuct = () => {
    return (
        <>
            <section className='product'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6'>
                            <div className='first-colume'>
                                <div className='heading-holder'>
                                    <h2>Audio Meet</h2>
                                    <h3>Everything you need to work together, all in one place.</h3>
                                    <Link to=''><p>Explore Audio Meet Collaboration Tools</p></Link>
                                </div>
                                <div className='productsubsection'>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                         <img src={virtualimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Virtual Meetings</h4>
                                            <p>Connect virtually from anywhere with Zoom Meetings</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={teamimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Team Chat</h4>
                                            <p>Collaborate together with Zoom Team Chat</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={voiimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>VoIP Phone System</h4>
                                            <p>Call the world with Zoom Phone</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={onlineimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Online Whiteboard</h4>
                                            <p>Create and brainstorm with Zoom Whiteboard</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={conversionalimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Conversation Intelligence</h4>
                                            <p>Rich conversation analytics to improve sales</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={emailimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Email and Calendar</h4>
                                            <p>Send and receive messages and calendar invitations</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={virtualworkimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Virtual Working Spaces</h4>
                                            <p>Bring fluid interactions to hybrid teams with Zoom Huddles</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='second-colume'>
                                <div className='mainsection'>
                                    <h2>Audio Spaces</h2>
                                    <h3>Innovative video solutions for every meeting space.</h3>

                                </div>
                                <div className='productsubsection'>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={roomimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Conference Room Systems</h4>
                                            <p>Bring meeting spaces online with Zoom Rooms</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={connectimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Connected Conference Rooms</h4>
                                            <p>Conference Room Connector links existing rooms to Zoom</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={spaceimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Workspace</h4>
                                            <p>Innovative solutions for every space</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='second-colume'>
                                <div className='mainsection'>
                                    <h2>Audio Events</h2>
                                    <h3>Solutions to host impactful virtual and hybrid experiences</h3>
                                     <p>Find a Solution for Every Event</p> 
                                </div>
                                <div className='productsubsection'>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={eventimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Event Platform</h4>
                                            <p>Host hybrid and virtual events with Zoom Events</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={singleimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Single-Session Events</h4>
                                            <p>Elevate your brand with single-session events powered by Zoom Sessions</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={webinarmg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Online Webinars</h4>
                                            <p>Broadcast at scale with Zoom Webinars</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={communityimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Community Events</h4>
                                            <p>Host and attend classes, group events, and more OnZoom</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='second-colume'>
                                <div className='mainsection'>
                                    <h2>Audio Contact Center</h2>
                                    <h3>Innovative video solutions for every meeting space.</h3>

                                </div>
                                <div className='productsubsection'>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={centerimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Cloud Contact Center</h4>
                                            <p>Engage customers with Zoom Contact Center</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-4'>
                                        <div className='img-product'>
                                        <img src={agentimg} className='img-meet me-3'/>
                                        </div>
                                        <div className=''>
                                            <h4>Virtual Agent</h4>
                                            <p>Deliver intelligent support with conversational AI</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Procuct