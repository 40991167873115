import React from 'react'
import Banner from  "./Banner/Banner";
 import EffectiveSystem from './EffectiveSystem/EffectiveSystem';
 import Feature from './Feature/Feature';
 import Educational from './Educational/Educational';


const Retails = () => {
  return (
    <div>
   
     <Banner/>
     <EffectiveSystem/>
     <Feature/>
     <Educational/>
      </div>
  )
}

export default Retails;