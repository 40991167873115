import React from 'react'
import PlansPricingBanner from './PlanPricingBanner/PlanPricingBanner';
import BilledPeriod from './BilledPeriod/BilledPeriod';
import BestValue from './BestValue/BestValue';
import CompareAll from './CompareAll/CompareAll';
import EssentialApp from './EssentialApp/EssentialApp';
import SliderTestimonial from './testimonial-slider/SliderTestimonial';
import Faq from './faq-folder/Faq';
import BussinessBilled from './bussinessbilled/BussinessBilled';
import ValuePlan from './valueplan/ValuePlan';
import Audio from './audio-card/Audio';
import EnjoyEssencial from './enjoy-essencial/EnjoyEssencial';
import Testimonial from './Testimonial/Testimonial';

const PlansPricingPage = () => {
  return (
    <>
      <PlansPricingBanner />
      <section className='pill-price-tab'>
        <div className='container'>
          <div className='row'>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-Personal" role="tabpanel" aria-labelledby="pills-Personal-tab" tabindex="0">
                <div className='row'>
                  <BilledPeriod />
                </div>
                <div className='first-tab-contant'>
                  <div class="tab-content" id="pills-tabContent1">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                      <div className='row'>
                        <BestValue />
                        <CompareAll />
                        <EssentialApp />
                        <SliderTestimonial />
                        <Faq />
                      </div>
                    </div>
                    <div class="tab-pane fade " id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                      ...
                    </div>
                    <div class="tab-pane fade " id="pills-ecommerce" role="tabpanel" aria-labelledby="pills-ecommerce-tab" tabindex="0">
                      ...
                    </div>
                    <div class="tab-pane fade " id="pills-health" role="tabpanel" aria-labelledby="pills-health-tab" tabindex="0">
                      ...
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade  " id="pills-Business" role="tabpanel" aria-labelledby="pills-Business-tab" tabindex="0">
                <div className='row'>
                  <BussinessBilled />
                </div>
                <div className='second-tab content'>
                  <div class="tab-content" id="pills-tabContent2">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                      <div className='row'>
                        <ValuePlan />
                        <Audio />
                        <EnjoyEssencial />
                        <Testimonial />
                        <Faq />
                      </div>
                    </div>
                    <div class="tab-pane fade  " id="pills-Team" role="tabpanel" aria-labelledby="pills-Team-tab" tabindex="0">
                      ...
                    </div>
                    <div class="tab-pane fade " id="pills-Phone" role="tabpanel" aria-labelledby="pills-Phone-tab" tabindex="0">
                      ...
                    </div>
                    <div class="tab-pane fade  " id="pills-Webinars" role="tabpanel" aria-labelledby="pills-Webinars-tab" tabindex="0">
                      ...
                    </div>
                    <div class="tab-pane fade  " id="pills-Rooms" role="tabpanel" aria-labelledby="pills-Rooms-tab" tabindex="0">
                      ...
                    </div>
                    <div class="tab-pane fade  " id="pills-Center" role="tabpanel" aria-labelledby="pills-Center-tab" tabindex="0">
                      ...
                    </div>
                    <div class="tab-pane fade  " id="pills-Whiteboard" role="tabpanel" aria-labelledby="pills-Whiteboard-tab" tabindex="0">
                      ...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default PlansPricingPage