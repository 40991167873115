import React from 'react'
import '../care-gaps-folder/caregap.css';
const CareGap = () => {
    return (
        <>
            <section className="care-gap">
                <div className="container">
                    <div className="row">
                        <h1 className="boundless">Close Care Gaps</h1>
                        <div className="text-holder">
                            <p >
                                The World Health Organization predicts a shortage of 18 million physicians, nurses, and support staff by 2030. Leading players in the Healthcare industry are using virtual care and telehealth to close gaps in care delivery and support.
                            </p>
                            <p >
                                Leverage the latest real-time engagement solutions to provide quality care from anywhere in the world
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CareGap