import React from 'react'
import '../PlanPricingBanner/planpricingbanner.css';

const PlanPricingBanner = () => {
  return (
    <>
      <section className="planBanner">
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='text-holder text-center mt-3 '>
                <h1 className=''>Audio Meets Pricing & Plans</h1>
              </div>
              <ul class="nav nav-pills mb-5 justify-content-center mt-5" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="pills-Personal-tab" data-bs-toggle="pill" data-bs-target="#pills-Personal" type="button" role="tab" aria-controls="pills-Personal" aria-selected="true">Personal</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="pills-Business-tab" data-bs-toggle="pill" data-bs-target="#pills-Business" type="button" role="tab" aria-controls="pills-Business" aria-selected="false">Business</button>
                </li>
              </ul>
           </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PlanPricingBanner