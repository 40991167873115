import React from "react";
import "./AboutUsBanner.css";
import aboutusbanner from "../imgs/aboutusbanner.png";
import Bannerimg1 from "../imgs/Bannerimg1.png";
import Bannerimg2 from "../imgs/Bannerimg2.png";
const Banner = () => {
  return (
    <>
   
      <section className="AboutUsBanner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7">
              <div className="">
                <h1 className="About-Us">About Us</h1>
              </div>
              <div className="col-md-9">
                <div className="text-holder_ideas">
                <h4 className="help">We help you to express</h4>
                  <p>
                    your thoughts & ideas and connect with each other
                    seamlessly. Where audio communication among the host and
                    other team members is private, secure, scalable, and smooth.
                    Founded in 2023 publicly traded and based in Pune,
                    Maharashtra.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="banner-holder mx-auto">
                <img src={Bannerimg1} alt="..." className="img-fluid ban-img1" />
                <img src={Bannerimg2} alt="..." className="img-fluid ban-img2" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
