import React from 'react';
import '../BilledPeriod/BilledPeriod.css';
import indiaflag from '../imgs/india-flag.png';
import usaflag from '../imgs/usa-flag.png' 
export const BilledPeriod = () => {
    return (
        <>
            <div className='row billed'>
                 <div className='d-flex justify-content-end'>
                    <div class="form-check my-3 mx-2">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Billed Annually<br /><span>SAVE 20%</span>
                        </label>
                    </div>
                    <div class="form-check my-3 mx-2">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                        <label class="form-check-label" for="flexRadioDefault2">
                            Billed Monthly
                        </label>
                    </div>
                </div>
           <ul class="nav nav-pills mb-5 justify-content-center mt-3" id="pills-tab" role="tablist">
              <li class="nav-item   me-3" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Audio Meets<br/> <span>Team Chat, Phone & More</span></button>
              </li>
              <li class="nav-item me-3" role="presentation">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Audio Meets Events<br/> and Webinars</button>
              </li>
              <li class="nav-item me-3" role="presentation">
                <button class="nav-link" id="pills-ecommerce-tab" data-bs-toggle="pill" data-bs-target="#pills-ecommerce" type="button" role="tab" aria-controls="pills-ecommerce" aria-selected="false">Audio Meets<br/> Rooms</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-health-tab" data-bs-toggle="pill" data-bs-target="#pills-health" type="button" role="tab" aria-controls="pills-health" aria-selected="false">Audio Meets<br/> Whiteboard</button>
              </li>
           </ul>
                <div className='d-flex justify-content-end'>
                    <div className='select-box'>
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Indian Rupee ₹</option>
                            <option value="1" >US Dollars $<img src={indiaflag} className='flag'/></option>
                            <option value="2">Indian Rupee ₹ <img src={usaflag} className='flag'/> </option>
                            
                        </select>
                    </div>
                    <div className='select-box'>
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Call from India</option>
                            <option value="1">USA</option>
                            <option value="2">England</option>
                            <option value="3">Australia</option>
                            <option value="3">India</option>
                            <option value="3">Australia</option>
                            <option value="3">Australia</option>
                        </select>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BilledPeriod;
