import React from 'react'
import './video.css';
import ReactPlayer from 'react-player'
const Video = () => {
    return (
        <>
            <section className='video-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='learning-holder'>
                                <h3>Reimagine Learning<br /> and Education</h3>
                                <p>Connect your greater school, college, or university community, and alumni with one Unified Communication and Collaboration Platform.
                                    Supporting your administrators and providing flexible learning modalities for students has never been easier.
                                    Now you can manage your communication procedures and policies as well as emergency protocols all within one platform.
                                    We invite you to see how Zoom Meetings, Team Chat, Phone, and Rooms can transform your workflow and bring your entire community together.</p>
                                <div className='btn btn-started'>Get Started</div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='video-meeting'>
                             <iframe width="560" height="315" src="https://www.youtube.com/embed/q8xKDJ6bUFI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Video