import React from 'react'
import treediagram from "../imgs/treediagram.png";
const EnjoyEssencial = () => {
  return (
    <>
    <div className="row essencial">
      <div className="backgroung">
        <div className="heading-holder text-center ">
          <h2>Enjoy Essential Apps for free!</h2>
        </div>
        </div>
        <div className="row">
        <div className="col-lg-6">
          <div className="text-holder mt-4">
            <p>The perfect all-in-one app suite to take meeting notes, engage your team with interactive activities, deliver insights to improve customer conversations and more!</p>
          </div>
        </div>
        <div className="col-lg-6">
           <div className="image-holder">
            <img src={treediagram} />
           </div>
          </div>
      </div>
   </div>
   <div className="row know">
    <div className="col-md-10 mx-auto">
      <div className="row">
      <div className="col-lg-6">
        <div className="heading-name">
          <h5>Did you find what you’re looking for?</h5>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="button-holder d-flex">
         <button className="btn btn-yes me-3">YES</button>
         <button className="btn btn-yes me-3">NO</button>
         <button className="btn btn-next">Next</button>
        </div>
      </div>
      </div>
      </div>
   </div>
    </>
  )
}

export default EnjoyEssencial