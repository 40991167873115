import React from "react";
import "./Educational.css";
import Mask1 from "../imgs/Mask1.png";
import Comp from "../imgs/Comp.png";
import { Link } from "react-router-dom";
const Educational = () => {
  return (
    <>
  
      <section className="Educational mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="button">
               <Link to="/EducationSolution"><p className="edu pt-2">Educational</p></Link>
              </div>
              <div className="list">
                <nav class="nav flex-column pb-1 pt-2">
                  <a class="nav-link active mb-2" aria-current="page" href="#">
                    Corporate
                  </a>
                  <a class="nav-link mb-2" href="#">
                    Government
                  </a>
                  <a class="nav-link mb-2" href="#">
                    Healthcare
                  </a>
                  <a class="nav-link mb-2" href="#">
                    Manufacturing
                  </a>
                  <a class="nav-link mb-2" href="#">
                    FMCG Company
                  </a>
                  <a class="nav-link mb-2" href="#">
                    Financial Services
                  </a>
                  <a class="nav-link mb-2" href="#">
                    University
                  </a>
                  <a class="nav-link mb-2" href="#">
                    Event Company
                  </a>
                  <a class="nav-link mb-2" href="#">
                    Transport
                  </a>
                </nav>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-12">
              <div className="background_grey">
                <div className="img-girls text-center">
                <img src={Mask1} className="Mask1-img img-fluid  mt-lg-0 mt-md-0 mt-0 m-xl-0 p-lg-4 p-md-4 p-4"/>
                </div>
               
                <p className="edu_institution my-2 p-4">
                  It helps educational institutions, teachers, and students to
                  get connected at a unified platform and then teach, learn and
                  connect at ease with one another.
                </p>
                <div className="read-button mb-2 me-3">
                <Link to="/EducationSolution"> <div className="btn btn-green">READ MORE</div></Link>
             </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Educational;
